.formContainer{
    max-width: 800px;
}
.parentInnerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.selectOrInput {
    min-width: 300px;
}

.parent-select {
    min-width: 187px;
    border: none;
    outline: solid 1px #9a9a9a;
    border-radius: 5px;
    padding: 5px;
    background-color: #FFFFFF;
}

.buttonToSaveParent {
    background-color: #1877F2;
    padding: 5px;
    border-radius: 5px;
    border: none;
    color: #FFFFFF;
    font-size: 14px;
    cursor: pointer;
}
.disabledButtonCSS{
    background-color: #7e7f81;
    padding: 5px;
    border-radius: 5px;
    border: none;
    color: #FFFFFF;
    font-size: 14px;
    pointer-events: none;
}
.buttonToAddParent{
    outline: 1px solid #1877F2;
    background-color: #FFFFFF;
    padding: 5px;
    border-radius: 5px;
    border: none;
    color: #1877F2;
    font-size: 14px;
    cursor: pointer;
}
.parentKBLabel{
    font-size: 14px;
    color: #5c5b5b;
    padding: 5px 0;
}
.contentInputContainer{
    width: 100%;
}
.KBButtons{
    padding: 10px 0;
    display: flex;
    justify-content: right;
}
.tox .tox-notification--in{
    display: none !important;
}
.tox:not([dir=rtl]) .tox-statusbar__branding{
    display: none !important;
    
}
.tox-menubar{
    display: block !important;
}
/* Details */
.detailsExpender{
    padding: 2px;
    position: relative;
}
.detailsSummaryTitle{
    padding: 10px;
    color: #393939;
    cursor: pointer;
    /* background-color: #cbcbcb; */
    border: 1px solid #cbcbcb;
    border-radius: 5px; 
}
.summaryInnerBody{
    padding: 10px 10px 10px 10px;
}
.rightFormContainer{
    min-width: 300px;
}
.contentContainerHTML{
    white-space: pre-wrap;
}
.contentContainerHTML p{
    white-space: pre-wrap;
}
.contentContainerHTML p img{
    border: 1px solid #393939;
    border-radius: 10px;
    width: 100%;
    height: auto;
}
.contentContainerHTML ol{
    padding: 0 15px;
}
.contentContainerHTML img{
    margin: 5px;
    border-radius: 5px;
    max-width: 600px;
}
.previewBoxVideo{
    padding: 10px;
}
