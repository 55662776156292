.total_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.total_ammount{
    padding: 10px 0 !important;
}
.invTtl{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #656565;
}
.invTable tr th{
    font-size: 12px !important;
    /* border: none !important; */
}
.account_ttl{
    font-size: 12px;
    padding: 0 0 10px 0;
}
.downloadinvoice{
    /* background-color: #656565; */
    padding: 10px;
    max-height: 72vh;
    overflow-y: auto;
}