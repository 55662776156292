.ticketReply{
    padding: 10px;
}
.ticketHeader{
    text-align: center;
    font-size: 24px;
    color: #1976D2;
}
.ticketDetailsGrid{
    border: 1px solid #dbd8d8;
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 60% auto;
}
.leftTicket{
    border-right: 1px solid #dbd8d8;
    padding-right: 10px;
}
.rightTicket{
    padding-left: 10px;
}
.ticketTitle{
    text-align: center;
    color: #69696a;
    font-size: 16px;
    font-weight: 500;
}
.deatilsGridForTicket{
    color: #69696a;
    display: grid;
    grid-template-columns: 30% 10px auto;
    font-size: 14px;
    gap: 5px;
    padding: 10px 0;
}
.ticketTite{
    font-weight: 500;
}
.subjectTicket{
    font-size: 14px;
    gap: 5px;
    color: #69696a;
    font-weight: 500;
    margin-top: 10px;
}
.ticketNames{
    font-size: 13px;
    color: #69696a;
}
.chitChatList{
    max-height: 590px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.ticketMessageBox{
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #1877F224;
    padding: 5px;
    gap: 5px;
}
.ticketText{
    margin-top: 10px;
    padding: 10px;
    border: none;
    outline: 1px solid #bfbfc2;
    width: 100%;
    border-radius: 5px;
    min-height: 100px;
}
.ticketText:focus{
    padding: 10px;
    border: none;
    width: 100%;
    border-radius: 5px;
    outline-color:#1877F2;
    caret-color: #1877F2;
    color: #656565;
}
.sendBtnTicket{
    font-size: 16px;
    border: none;
    color: #1877F2;
    background-color: transparent;
    display: flex;
    justify-content: right;
    align-items: center;
    cursor: pointer;
    background-color: #1877F224;
    padding: 3px 10px;
    border-radius: 40px;
}
.ticketResponseSection{
    display: flex;
    flex-direction: column;
    max-height: 550px;
    overflow-y: auto;
    gap: 10px;
}
.replyRow{
    /* padding: 5px; */
    text-align: left;
    border: 1px solid #ceced0;
    border-radius: 10px;
    /* overflow: hidden; */
}
.meassgeHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
    font-size: 14px;
    background-color: #1877F224;
    color: #1877F2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.ticketContent{
    color: #656565;
    font-size: 13px;
    padding: 10px 5px;
}
.personNameTicket{
    display: inline-flex;
    align-items: center;
    gap: 5px;
}
.messageTimeTicket{
    display: inline-flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
}
.replyRowRight{
    padding: 5px;
    text-align: right;
}
.ticketResponseHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.closeTicket button{
    background-color: #c03d3d;
    border: 3px solid #FFFFFF;
    outline: 1px solid #c03d3d;
    border-radius: 40px;
    padding: 3px 10px;
    color: #FFFFFF;
    cursor: pointer;
}
.leftReply{
    background-color: #1877F2;
    color: #FFFFFF;
    font-size: 12px;
    padding: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: inline-flex;
    gap: 5px;
}
.rightReply{
    background-color: #1877F2;
    color: #FFFFFF;
    font-size: 12px;
    padding: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: inline-flex;
    gap: 5px;
}
.replyTimeTicket{
    color: #c6c3c3;
    font-size: 11px;
}
.newTicket{
    color: #04a33e;
}
.pendingTicket{
    color: #ba7704;
}
.closedTicket{
    color: #a00505;
}

/* Popup */

/* .customers_page{
    position: relative;
} */
.assignPopup{
    position: absolute;
    top: -9999px;
    right: 0;
    bottom: 9999px;
    left: 0;
    background-color: #00000042;
}
.assignPopupShow{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #00000042;
    z-index: 999999;
}
.assignPopupInner{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.containerTicket{
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 10px;
    position: relative;
}
.cutAssign{
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #1877F224;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #a00505;
    font-size: 18px;
}
.cutAssign:hover{
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #a0050524;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #a00505;
    font-size: 18px;
}
.assign_heading{
    color: #1877F2;
    padding-bottom: 10px;
}
.ticketDetailsContainer{
    min-width: 400px;
    /* padding-top: 14px; */
}
.chooseEmployeeSection{
    padding-top: 14px;
}
.selectEmployee{
    padding: 10px;
    border: none;
    outline: 1px solid #c6c3c3;
    font-size: 14px;
    color: #656565;
    background-color: transparent;
    border-radius: 5px;
    min-width: 200px;
}
.selectEmployee:focus{
    padding: 10px;
    border: none;
    outline: 1px solid #1877F2;
    font-size: 14px;
    color: #656565;
    background-color: transparent;
    border-radius: 5px;
    min-width: 200px;
}
.assignBtn{
    padding: 10px 0 0 0;
    text-align: center;
}
.assignBtn button{
    padding: 5px 10px;
    background-color: #1877F2;
    border: 3px solid #FFFFFF;
    border-radius: 40px;
    outline: 1px solid #1877F2;
    color: #FFFFFF;
    font-size: 14px;
    width: 50%;
}
.messageBoxTicket{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    gap: 10px;
}
.ssContainer img{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}
.coreImageTicket{
    padding: 10px 5px 10px 5px;
}
.coreImageTicket img{
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.attachResponse input{
    
}

/* Re Assign btn */
.reAssign{
    position: absolute !important;
    left: 0;
    background-color: #69696a;
    border: none;
    color: #FFFFFF;
    padding: 5px;
    border-radius: 5px;
    top: -5px;
    cursor: pointer;
    opacity: 0;
}
.assignTickets{
    background-color: #69696a;
    border: none;
    color: #FFFFFF;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}
.assignTickets:hover{
    background-color: #1877F2;
    border: none;
    color: #FFFFFF;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}
.reAssign:hover{
    position: absolute !important;
    left: 0;
    background-color: #3cca43;
    border: none;
    color: #FFFFFF;
    padding: 5px;
    border-radius: 5px;
    top: -5px;
    cursor: pointer;
    opacity: 1;
}