.employee-profile-left {
    flex-wrap: wrap;
    display: flex;
    gap: 10px;
    width: 100%;
    background-color: #1877f2;
    color: #FFFFFF;
    border-radius: 5px;
}

.profile-data-grid{
    max-width: 350px;
}