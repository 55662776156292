.customer-invoice {
  /* padding: 10px; */
  /* position: relative; */
}

/* Payment Popup CSS */
.payment_popup {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000d9;
  /* display: none; */
}

.paymentInner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.company_addressDetails {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 20px;
  position: relative;
}

.closeBtn {
  position: absolute;
  top: -11px;
  right: -15px;
  background-color: #f3070773;
  color: #fd0000;
  border-radius: 50%;
  font-size: 26px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}
.closeBtn:hover{
  background-color: #ffffff;
  transition: all 0.3s;
}
.pay_head {
  text-align: center;
  color: #393939;

}

.detailSection {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-top: 20px;
}

.paymentFor h4 {
  color: #555454;
  padding: 10px 0;
}

.paymentFor .compName {
  color: #555454;
  font-size: 15px;
  font-weight: 600;
}

.paymentFor .compAddr {
  display: flex;
  flex-direction: column;
  justify-content: left;
  color: #555454;
  padding: 5px;
  font-size: 14px;
  gap: 3px;
}

.paymentFor .gstNumber {
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #555454;
  padding: 5px;
}

.payDetails {
  padding: 10px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.lableSide {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #f5f3f3;
  color: #555454;
}

.lableSide .paylabel {
  font-weight: 600;
  font-size: 14px;
}

.lableSide .payInput {
  font-size: 14px;
}

.payInput .inputPay {
  border: none;
  outline: 1px solid #555454;
  padding: 10px;
  border-radius: 5px;
  color: #555454;
  font-size: 14px;
  min-width: 200px;
  background-color: #ffffff;
}

.inputPay option {
  color: #555454;
}

.payBtns {
  width: 100%;
}

.payBtns button {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: #1976d2;
  color: #ffffff;
  font-size: 16;
  font-weight: 600;
  letter-spacing: 1px;
}
/* Apply custom styles to the date picker container */
.datepicker-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Apply custom styles to the date picker itself */
.custom-datepicker {
  font-size: 1.2rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
}

/* Payment Popup End */
.section-title {
  padding: 10px 0 10px 0;
  font-size: 16px;
  font-weight: 500;
}

.invoice_inner {
  /* padding: 10px; */
  border-radius: 10px;
  /* box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px; */
  height: auto;
  text-align: center;
}

.add-icons {
  height: 19px;
  width: 19px;
}

.invoice-create-btn {
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 40px;
  background-color: #1976d2;
  outline: 1px solid #1976d2;
  border: 2px solid #ffffff;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.invoice_card_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  text-align: left !important;
}

.search_field {
  border: none !important;
  outline: none !important;
  border-radius: 40px !important;
  background-color: #eff0f1;
  padding-right: 20px !important;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

/* -------------invoice table ----------- */
.invoice-table {
  width: 100%;
}

.invoice-table table {
  width: 100%;
  border-collapse: collapse;
}

.customer_table table thead tr {
  background-color: #f1eeee;
}

.invoice-table table thead tr th {
  padding: 10px 5px;
  color: #393939;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.invoice-table table tbody tr td {
  font-size: 12px;
  color: #393939;
  padding: 10px 5px;
  border-bottom: 1px solid #f3f2f2;
  height: 30px;
  text-align: center;
}

.accept_pmnt {
  padding: 3px 10px;
  border-radius: 40px;
  border: none;
  background-color: #1976d2;
  color: #ffffff;
  cursor: pointer;
}

.successBtn {
  color: green !important;
  padding: 0 !important;
  height: 20px;
  width: 50px;
  border: none !important;
}

.warnBtn {
  color: rgb(216, 133, 25) !important;
  padding: 0 !important;
  width: auto;
  padding: 5px 0 !important;
  height: 30px;
  border: none !important;
}

.errorBtn {
  color: rgb(240, 52, 27) !important;
  padding: 0 !important;
  width: auto;
  padding: 5px 0 !important;
  height: 30px;
  border: none !important;
}

.invoice-title {
  font-size: 16px;
  text-decoration: none;
}

.action-wrapper {
  display: none;
  gap: 10px;
}

.invoice-title-section {
  position: relative;
  gap: 10px;
}

.invoice-title-section:hover .action-wrapper {
  position: absolute;
  display: flex;
  text-decoration: none;
  gap: 10px;
}

.action-wrapper a {
  text-decoration: none;
}

.action-btns a {
  margin-left: 10px;
  text-decoration: none;
  text-align: center;
}

.pay_Btn {
  margin-left: 10px !important;
  width: auto;
  text-align: left;
}

.buttons_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.invoice_table {
  width: 100%;
}

.invoice_table table {
  width: 100%;
  border-collapse: collapse;
}

.invoice_table table thead tr th {
  padding: 10px 5px;
  color: #393939;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  border-bottom: 1px solid #f3f2f2;
}

.invoice_table table tbody tr td {
  font-size: 12px;
  color: #393939;
  padding: 10px 5px;
  border-bottom: 1px solid #f3f2f2;
  height: 30px;
  text-align: left;
}