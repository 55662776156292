.customer-invoice {
  /* padding: 10px; */
}

.section-title {
  padding: 10px 0 10px 0;
  font-size: 16px;
  font-weight: 500;
}

.invoice-inner {
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px;
  height: auto;
  text-align: center;
}

.add-icons {
  height: 19px;
  width: 19px;
}

.invoice-create-btn {
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 40px;
  background-color: #1976d2;
  outline: 1px solid #1976d2;
  border: 2px solid #ffffff;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.invoice-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  text-align: left !important;
}

.searchField {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* -------------invoice table ----------- */
.invoice-table {
  width: 100%;
}

.invoice-table table {
  width: 100%;
  border-collapse: collapse;
}

.customer_table table thead tr {
  background-color: #f1eeee;
}

.invoice-table table thead tr th {
  padding: 10px 5px;
  color: #393939;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.invoice-table table tbody tr td {
  font-size: 12px;
  color: #393939;
  padding: 10px 5px;
  border-bottom: 1px solid #f3f2f2;
  height: 30px;
  text-align: center;
}

.successBtn {
  color: green !important;
  padding: 0 !important;
  height: 20px;
  width: 50px;
  border: none !important;
}

.warnBtn {
  color: rgb(216, 133, 25) !important;
  padding: 0 !important;
  width: auto;
  padding: 5px 0 !important;
  height: 30px;
  border: none !important;
}

.errorBtn {
  color: rgb(240, 52, 27) !important;
  padding: 0 !important;
  width: auto;
  padding: 5px 0 !important;
  height: 30px;
  border: none !important;
}

.invoice-title {
  font-size: 16px;
  text-decoration: none;
}

.action-wrapper {
  display: none;
  gap: 10px;
}

.invoice-title-section {
  position: relative;
  gap: 10px;
}

.invoice-title-section:hover .action-wrapper {
  position: absolute;
  display: flex;
  text-decoration: none;
  gap: 10px;
}

.action-wrapper a {
  text-decoration: none;
}

.action-btns a {
  margin-left: 10px;
  text-decoration: none;
  text-align: center;
}

.payBtn {
  margin-left: 10px !important;
  width: auto;
  text-align: center;
}

.buttons-wrapper {
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
}