@import url('https://fonts.googleapis.com/css2?family=Muli&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.full-invoice {
    width: 100%;
    height: auto;
    background-color: #FFFFFF;
}

/* invoice sheet styling start here====>>>>>>>>>> */
.invoice_sheet {
    width: 100%;
    height: auto;
    padding: 20px;
    margin: auto;
}

.invoice_header {
    width: 100%;
    height: auto;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    margin: auto;
}

.user_details .com-logo {
    width: 80%;
    height: auto;
}

.user_details .com-logo img {
    width: 50%;
    height: auto;
}

.invoice_header .user_details {
    width: 100%;
    height: auto;
    padding: 10px;
    text-align: left;
    line-height: 20px;
}

.user_details .user_bill h4 {
    font-size: 17px;
    font-family: 'Muli', sans-serif;
    font-weight: 600;
    margin-top: 10px;
    color: #181E40;
}

.user_details .group p {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 18px;
    font-family: 'Muli', sans-serif;
    font-weight: 500;
    color: #393939;
}

.user_details .user_add p {
    font-size: 14px;
    font-family: 'Muli', sans-serif;
    font-weight: 400;
    color: rgb(100, 116, 139);
}

.user_details .invoice_date h5 {
    margin-top: 20px;
    font-size: 14px;
    font-family: 'Muli', sans-serif;
    font-weight: 600;
    color: #393939;
}

.user_details .Due_date h5 {
    font-size: 14px;
    font-family: 'Muli', sans-serif;
    font-weight: 600;
    color: #393939;
}

.user_details .agent h5 {
    font-size: 14px;
    font-family: 'Muli', sans-serif;
    font-weight: 600;
    color: #393939;
}

.invoice_header .user_invoice_number {
    width: 100%;
    height: auto;
    padding: 10px;
    text-align: right;
    line-height: 20px;
    margin-top: 50px;
}

.user_invoice_number .user_invoice h3 {
    font-size: 20px;
    font-family: 'Muli', sans-serif;
    font-weight: 600;
    color: #181E40;
}

.user_invoice_number .com_prefex h5 {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 18px;
    font-family: 'Muli', sans-serif;
    font-weight: 500;
    color: #181E40;
}

.user_invoice_number .com_details p {
    font-size: 16px;
    font-family: 'Muli', sans-serif;
    font-weight: 500;
    color: rgb(100, 116, 139);
}

/* invoice details styling start here====>>>>>>>> */
.Invoice_details {
    width: 100%;
    height: auto;
    padding: 20px;
    margin: auto;
}

.grid-container {
    width: 100%;
    height: auto;
    margin: auto;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    gap: 0px;
    padding: 20px;
}

.grid-container,
.series,
.item,
.qty,
.rate,
.tax,
.ammount {
    color: #393939;
}

.grid-container>div {
    padding: 5px 0;
    font-size: 16px;
}

.grid-container .item h5 {
    text-align: left;
    color: rgb(100, 116, 139);
}

.grid-container .item p {
    text-align: left;
    color: #393939;
    margin-top: 3px;
}

.total_ammount {
    width: 100%;
    height: auto;
    margin: auto;
    /* padding: 16px 20px 60px 20px; */
}

.total_ammount .sub_total {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin: auto;
}

.sub_total .total_amm {
    width: 40%;
    height: auto;
    border-top: 1px solid #393939;
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;
}

.total_amm .first_span {
    margin: auto 58px;
    color: #181E40;
    font-weight: 600;
}

.total_amm .second_span {
    margin: auto 98px;
    color: #393939;
}

/* terms and condition styling start here====>>>>> */
.terms_condition {
    width: 100%;
    height: auto;
    padding: 20px;
    margin: auto;
}

.terms_condition .terms {
    width: 100%;
    height: auto;
    margin: auto;
    padding: 20px 0px;
    border-top: 1px solid #393939;
    border-bottom: 1px solid #393939;
}

.terms .condition_heading h5 {
    font-size: 16px;
    letter-spacing: 1px;
    font-family: 'Muli', sans-serif;
    font-weight: 600;
    color: #181E40;
}

.terms .conidtion_para p {
    font-size: 14px;
    margin-top: 10px;
    font-family: 'Muli', sans-serif;
    font-weight: 400;
    color: rgb(100, 116, 139);
}

/* transaction part start here====>>>>>>... */
.transaction {
    width: 100%;
    height: auto;
    margin: auto;
    padding: 20px;
}

.transaction .trans {
    width: 100%;
    padding: 20px 0px;
    margin: auto;
    border-top: 1px solid #393939;
    border-bottom: 1px solid #393939;
}

.trans .trans_head h5 {
    font-size: 15px;
    padding-bottom: 10px;
    font-family: 'Muli', sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    color: #181E40;
}

.trans .table {
    width: 100%;
    height: auto;
    text-align: left;
    border-collapse: collapse;
}
 .first_row{
    background-color: #F1EEEE;
}
.table th {
    padding: 10px;
    font-family: 'Muli', sans-serif;
    font-weight: 600;
    color: #181E40;
}

.table td {
    border-top: 1px solid #393939;
    padding: 10px;
    font-family: 'Muli', sans-serif;
    font-weight: 400;
    color: rgb(100, 116, 139);
}

/* responsive design  */

@media (max-width:320px) {
  .full-invoice {
      width: 90%;
  }

  .invoice_sheet {
      padding: 0;
  }

  .invoice_header {
      display: block;
      padding: 9px;
  }

  .invoice_header .user_invoice_number {
      text-align: left;
      margin-top: 10px;
  }

  .Invoice_details {
      padding: 0;
  }

  .grid-container {
      padding: 7px;
      gap: 2px;
  }

  .sub_total .total_amm {
      width: 100%;
      height: auto;
  }

  .total_amm .first_span {
      margin: auto 8px;
  }

  .total_amm .second_span {
      margin: auto 47px;
  }

  .total_ammount {
      padding: 10px;
  }

  .terms_condition .terms {
      padding: 0;
      padding-top: 10px !important;
  }

  .table th {
      padding: 0;
  }

  .user_details .com-logo img {
      width: 80%;
      height: auto;
  }
}

@media (min-width:321px) and (max-width:480px) {
  .full-invoice {
      width: 90%;
  }

  .invoice_sheet {
      padding: 0;
  }

  .invoice_header {
      display: block;
      padding: 9px;
  }

  .invoice_header .user_invoice_number {
      text-align: left;
      margin-top: 10px;
  }

  .Invoice_details {
      padding: 0;
  }

  .grid-container {
      padding: 12px;
      gap: 0px;
  }

  .sub_total .total_amm {
      width: 70%;
      height: auto;
  }

  .total_amm .first_span {
      margin: auto 8px;
  }

  .total_amm .second_span {
      margin: auto 47px;
  }

  .total_ammount {
      padding: 10px;
  }

  .terms_condition .terms {
      padding: 0;
      padding-top: 10px !important;
  }

  .table th {
      padding: 0;
  }

  .user_details .com-logo img {
      width: 62%;
      height: auto;
  }
}

@media (min-width:481px) and (max-width:680px) {
  .full-invoice {
      width: 90%;
  }

  .invoice_sheet {
      padding: 0;
  }

  .invoice_header {
      display: block;
      padding: 9px;
  }

  .invoice_header .user_invoice_number {
      text-align: left;
      margin-top: 10px;
  }

  .Invoice_details {
      padding: 0;
  }

  .grid-container {
      padding: 12px;
      gap: 20px;
  }

  .sub_total .total_amm {
      width: 50%;
      height: auto;
  }

  .total_amm .first_span {
      margin: auto 8px;
  }

  .total_amm .second_span {
      margin: auto 47px;
  }

  .total_ammount {
      padding: 10px;
  }

  .terms_condition .terms {
      padding: 0;
      padding-top: 10px !important;
  }

  .table th {
      padding: 0;
  }

  .user_details .com-logo img {
      width: 50%;
      height: auto;
  }
}

@media (min-width:681px) and (max-width:767px) {
  .full-invoice {
      width: 90%;
  }

  .invoice_sheet {
      padding: 0;
  }

  .invoice_header {
      display: flex;
      padding: 9px;
  }

  .invoice_header .user_invoice_number {
      text-align: left;
      margin-top: 10px;
  }

  .invoice_header .user_invoice_number {
      text-align: right;
      margin-top: 46px;
  }

  .Invoice_details {
      padding: 0;
  }

  .grid-container {
      padding: 12px;
      gap: 20px;
  }

  .sub_total .total_amm {
      width: 50%;
      height: auto;
  }

  .total_amm .first_span {
      margin: auto 8px;
  }

  .total_amm .second_span {
      margin: auto 47px;
  }

  .total_ammount {
      padding: 10px;
  }

  .terms_condition .terms {
      padding: 0;
      padding-top: 10px !important;
  }

  .table th {
      padding: 0;
  }

  .user_details .com-logo img {
      width: 70%;
      height: auto;
  }
}

@media (min-width:768px) and (max-width:1024px) {
  .full-invoice {
      width: 90%;
  }

  .invoice_sheet {
      padding: 0;
  }

  .invoice_header {
      display: flex;
      padding: 9px;
  }

  .invoice_header .user_invoice_number {
      text-align: left;
      margin-top: 10px;
  }

  .invoice_header .user_invoice_number {
      text-align: right;
      margin-top: 46px;
  }

  .Invoice_details {
      padding: 0;
  }

  .grid-container {
      padding: 12px;
      gap: 20px;
  }

  .sub_total .total_amm {
      width: 100%;
      height: auto;
  }

  .terms_condition .terms {
      padding: 0;
      padding-top: 10px !important;
  }

  .table th {
      padding: 0;
  }

  .user_details .com-logo img {
      width: 70%;
      height: auto;
  }

  .total_ammount {
      width: 100%;
  }
  
  .total_ammount .sub_total {
      width: 100%;
  }
  
  .sub_total .total_amm {
      width: 40%;
      height: auto;
  }
  
  .total_amm .first_span {
      margin: auto 26px;
      font-weight: bold;
  }
  
  .total_amm .second_span {
      margin: auto 0px;
  }
}



