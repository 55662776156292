.App {
  width: 100%;
  height: 100vh;
  /* overflow: hidden; */
  /* background-color: #f4f4f4; */
}
.proposal_customer_btnHead{
  display:  flex;
}
@media print
    {
        #sideMain { display: none; }
        #printable { display: block; }
        .view_heading{display: none;}
    }


    .cs-custom-error-msg {
      font-size: 12px;
      color: rgb(95, 33, 32);
      display: flex;
      gap: 10px;
      align-items: center;
      padding: 5px 0;
    }
    .cs-custom-error-msg svg {
      color: #ef5350;
      height: 20px;
    }

    .chat-whole-container {
      display: flex;
      height: 90vh;
  }
.chat-side-bar{
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
}
.side-search {
  width: 100%;
  height: 8%;
  background-color: #1877f2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-item{
  display: flex;
  align-items: center;
  gap: 5px;
  /* background-color: #d9d9d9; */
  border-bottom: 1px solid #d9d9d9;
  padding: 5px;
}
.list-item:hover{
  background-color: #d9d9d9;
  cursor: pointer;
}
.symbolic-name{
  /* padding: 10px; */
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1877f229;
  color: #1877f2;
  border-radius: 50%;
  text-transform: capitalize;
}
.customer-name{
  /* width: 100%; */
  /* background-color: #d9d9d9; */
  text-transform: capitalize;
}
.chat-body{
  width: 85%;
  height: 100%;
  /* background-color: aquamarine; */
  display: flex;
  /* padding: 5px; */
  flex-direction: column;
  justify-content: space-between;
  
}
.chat-nav-bar{
  background-color: #1877f2;
  height: 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}
.chat-panel{
  /* background-color: #ef5350; */
  height: 80%;
  overflow: auto;
}

.chat-bubble-right{
  border: 1px solid #1877f2;
  padding: 5px;
  border-radius: 5px 5px 0 5px;
  background-color: #1877f229;
  color: #1877f2;
  font-size: 14px;
}
.chat-bubble-left{
  border: 1px solid #595959;
  padding: 5px;
  border-radius: 0 5px 5px 5px;
  background-color: #59595905;
  color: #595959;
  font-size: 14px;
}
.chat-input{
  background-color: #1877F219;
  height: 8%;
  /* border-top: 1px solid #d9d9d9; */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 0 5px 0 5px;

}
.message-input{
  border: none;
  width: 95%;
  height: 100%;
  padding: 5px;
  outline: none !important;
}
.assign-emp-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.plugAssign{
  font-size: 31px;
  color: #1877f2;
  transform: rotate(112deg);
}
.file-input-container {
  position: absolute;
  bottom: 55px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.file-input-box {
  position: relative;
  width: 40px;
  height: 40px;
  background: #1877f249;
  color: #1877f2;
  
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.file-input-box span{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-type{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}
.unread-circle{
  padding: 2px;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #f19205;
    color: #FFFFFF;
    position: absolute;
    top: -4px;
    left: -39px;
    font-size: 11px;
}
.New-circle{
  background-color: #1877f2;
  color: #FFFFFF;
  padding: 2px 10px;
  border-radius: 10px;
  font-size: 11px;
}

.notification-counter-circle{
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: #f19205;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 50%;
  font-weight: 500;
}