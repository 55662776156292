@import url('https://fonts.googleapis.com/css2?family=Muli&display=swap');

.login-page {
  width: 100%;
  height: 100vh;
  color: var(--text-color);
  font-family: 'Muli', sans-serif;
  margin: auto;
  overflow: hidden;
  background: linear-gradient(270deg, #0F172A 0%, rgba(32, 37, 139, 0.88) 51.63%, #0F172A 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-page .agent_details {
  padding: 10px;
  min-width: 400px;
  height: auto;
  margin: auto;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 5px 5px 20px 1px rgba(0, 0, 0, 0.10), -5px -5px 10px rgba(0, 0, 0, 0.10);
}

.login-page .agent_details form {
  width: 100%;
  height: auto;
  margin: auto;
  padding: 20px;
}

.login-page .agent_details form label {
  color: #475569;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  top: -18px;
  /*  left: 20px;*/
  background-color: #f2f2f2;
  padding: 0px 5px;
}

.icon {
  padding: 10px;
  color: #aeaec1;
  min-width: 50px;
  text-align: center;
}

.sociel_media {
  display: flex;
  justify-content: center;
}

.fa {
  padding: 20px;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50px;
}

.login-page .fa-facebook {
  background: #3B5998;
  color: #fff;
  width: 40px;
  height: 40px;
  padding: 12px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.login-page .fa-google {
  background: #ffffff;
  color: rgb(236, 67, 16);
  width: 40px;
  height: 40px;
  padding: 12px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.login-page .fa-pinterest {
  background: #cb2027;
  color: #fff;
  width: 40px;
  height: 40px;
  padding: 12px;
}

.login-page .container h1 {
  color: #475569;
  font-size: 26px;
  text-align: left;
  padding: 10px;
  text-align: center;
}

.login-page .form-control {
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-page .agent_details form {
  display: flex;
  justify-content: space-around;
  gap: 100px;
  margin: 0;
}

.login-page form .col_1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.login-page .line_1 {
  position: relative;
  width: 100%;
}

.login-page .form-control input {
  background-color: transparent;
  border: none;
  width: 100%;
  height: auto;
  padding: 10px 5px;
  font-size: 18px;
  color: var(--text-color);
  outline: none;
}

.login-page .form-control:focus {
  border-bottom: 1px solid #8782db;
}

::placeholder {
  color: #aeaec1;
  font-family: 'Muli', sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.login-page .end_section button {
  width: 100%;
  color: #fff;
  font-family: 'Muli', sans-serif;
  overflow: hidden;
  cursor: pointer;
  padding: 10px 15px;
  border: 0;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600;
  background: linear-gradient(270deg, #0F172A 0%, rgba(32, 37, 139, 0.88) 51.63%, #0F172A 100%);
}

.end_section button:hover {
  background-color: #5e86df;
}

.login-page form a {
  text-align: center;
  color: #000;
  text-decoration: none;
}

.login-page form .forget {
  text-align: right;
  font-size: 14px;
  color: #1877F2;
  cursor: pointer;
}

.login-page form span {
  color: #2563EB;
  font-size: 14px;
}

/* ####################FORGOT PASSWORD########################### */
.forgot-dialog-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #00000080;
}

.forgot-dialog-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.forgot-dialog {
  background: #FFFFFF;
  padding: 10px;
  border-radius: 10px;
  max-width: 400px;
}

.forgot-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.forgot-heading {
  font-size: 18px;
  font-weight: 600;
  color: #475569;
}

.forgot-quote {
  text-align: center;
  padding: 0 10px;
  font-weight: 500;
  color: dimgrey;
}

.forgot-steps {
  font-size: 14px;
  font-weight: 600;
  color: dimgray;
  text-align: center;
}

.otpInput {
  width: 2.2rem !important;
  height: 2.2rem;
  margin: 0 0.25rem;
  font-size: 1rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline: none;
}

.otpContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
}