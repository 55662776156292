.leads-main {
  padding: 10px;
}


.leads-section-title .create-leads-title {
  padding: 10px 0 10px 0;
  font-size: 16px;
  font-weight: 500;
}
.lead-form-boundary{
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px;
}
.leads-inner{
  padding: 10px;
  
  height: auto;
}
.create-leads-inner {
  width: fit-content;
  padding: 10px;
  
  height: auto;
}
.action_btn {
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 3px;
  color: #1976D2 !important;
  font-size: 22px !important;
}

.action_btn .action_icon_views {
  color: #1976D2;
  cursor: pointer;
}

.lead-action-wrapper .leads-section-title {
  border: 1px solid #aaaab3;
  width: auto;
  height: auto;
  padding: 5px;
  border-radius: 5px;
  outline: none;
  min-width: 72px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.filterToggle {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.filterToggle ul {
  z-index: 11;
  position: absolute;
  list-style: none;
  width: 100px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 10px;
  overflow: hidden;
  right: 18px;
  top: 30px;
  color: rgb(56, 52, 52);
  background-color: rgb(163, 152, 152);
}

.filterToggle ul li {
  height: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterToggle ul li:hover {
  background-color: rgb(82, 75, 75);
  color: #ffffff;
  cursor: pointer;
}

.tableFilterToggle {
  top: -25px;
  right: 56px;
}

.leads-table {
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (max-width: 990px) {
  .leads-table {
    overflow-x: auto; 
    /* Enable horizontal scrolling on larger screens */
  }
}
.leads-table table {
  width: 100%;
  border-collapse: collapse;
  /* margin-top: 10px; */
}

.leads-table table thead tr {
  background-color: #f1eeee;
  position: sticky;
  top: 0;
  z-index: 99;
}

.leads-table table tbody tr:nth-child(even) {
  background-color: #1877F229;
}

.leads-table table thead tr th {
  padding: 10px 5px;
  color: #393939;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  padding: 10px 3px;
  border-bottom: 1px solid #39393921;
}

.leads-table table tbody tr td {
  font-size: 12px;
  color: #393939;
  padding: 10px 5px;
  border-bottom: 1px solid #f3f2f2;
  border: none;
  height: 35px;
  text-align: left;
}

.icons-wrapper {
  display: flex;
  justify-content: left;
  align-items: left;
  gap: 10px;
  border: none;
}

.icons-wrapper .icons {
  height: 20px;
  width: 20px;
  color: rgb(7, 61, 131);
  cursor: pointer;
  border: none;
}

.selected-action-button {
  background-color: #3b3b3b;
  color: #f3f1f1;
  outline: none;
  border: none;
}

.lead-priority {
  height: auto;
  width: auto;
  font-size: 14px !important;
  /* font-weight: 500 !important; */
}

.average-class {
  color: #ff7700 !important;
}

.high-class {
  color: red !important;
}

.normal-class {
  color: #7a5205 !important;
}

.pending-class {
  color: rgb(212, 70, 70) !important;
}

.converted-class {
  color: green !important;
}

.new-class {
  color: rgb(188, 88, 26) !important;
}

/* ------------- Create Leads --------- */
.create-leads {
  padding: 10px;
  background-color: #ffffff;
}

.create-leads-title {
  font-weight: 600;
  font-size: 16px;
  color: #334165;
  margin: 0 0 15px 0;
}

.create-leads-header {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding: 25px 0;
}

.dropdown select {
  height: 30px;
  border: 1px solid rgb(179, 173, 173);
}

.dropdown span {
  color: red;
}

.dropdown p {
  color: #475569;
}

.create-leads-body {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border-bottom: 1px solid #eeeeee;
  padding: 10px;
}

.create-leads-body input {
  width: 100%;
  max-width: 250px;
  height: 30px;
  color: #334165;
}

.create-leads-body  textarea {
  height: 50px;
  width: 400px;
  border: none;
  outline: 1px solid #a9a9a9;
  border-radius: 5px;
  padding: 5px;
}

.create-leads-body textarea:focus {
  height: 50px;
  /* width: 200px; */
  border: none;
  outline: 1px solid #1877F2;
  border-radius: 5px;
  padding: 5px;
}

.saveBtn {
  width: auto;
  background: rgb(56, 75, 160);
  color: white;
  font-size: 14px;
  font-weight: 500;

}

.saveBtn:hover {
  background-color: rgb(56, 75, 160);
  font-size: 14px;
  transition: all 0.5s;
}

.lead-action-wrapper {
  display: flex;
  text-align: center;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}

/* ------------- View Leads --------- */

/* -------------Attachment --------- */
.attachdiv {
  width: 700px;
  height: 150px;
  background-color: rgb(211, 224, 238);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  padding: 20px;
  border-radius: 10px;
  border: 1px dashed gray;
  position: relative;
}

.attachdiv input {
  opacity: 0;
  z-index: 9;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.attachdiv p {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 1; */
  color: #334165;
}

/* ------------Notes----------- */
.notesTextarea {
  height: 110px;
  width: 450px;
  outline: none;
  padding: 10px;
}

.profileCard {
  width: auto;
  height: auto;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 7px 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.avatar-logo {
  height: 35px;
  width: 35px;
  color: #808dac;
}

.profile-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.profile-details .username {
  font-size: 14px;
  color: #334165;
  font-weight: 500;
}

.profile-details .dateHistory {
  font-size: 12px;
  color: #475569;
  font-weight: 500;
}

.profile-details .todoText {
  font-size: 16px;
  color: #4c3386;
  font-weight: 500;
}

/* ----------------Popup profile --------------- */
.ongoing_popup_hide {
  position: absolute;
  background-color: #00000081;
  top: -1000px;
  left: 0;
  right: 0;
  bottom: 2000px;
  z-index: 9;
  transition: all 1s;
  /* height: 0px; */
}

.popup-card {
  height: auto;
  width: auto;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.popup-main {
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1976d2;
}

.ongoing_popup {
  position: absolute;
  background-color: #00000081;
  top: 0 !important;
  left: 0;
  right: 0;
  bottom: 0 !important;
  z-index: 100;
}

.task-details-main {
  min-width: 375px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 10px 10px 10px;
}

.task-details-main .flex-class {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 40% 3% 55%;
  justify-content: space-between;
  font-size: 14px;
}

.link-wrapper {
  display: flex;
  width: 100%;
  gap: 10px;
}

.link-wrapper a {
  color: #393939;
  border: 1px solid #aaaab3;
  width: auto;
  height: auto;
  padding: 6px;
  border-radius: 5px;
  outline: none;
  min-width: 72px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
}

.active-class {
  color: #f3f1f1 !important;
  background-color: #3b3b3b;
}

.active-class,
.link-wrapper a:hover,
.link-wrapper a:focus {
  color: #f3f1f1 !important;
  background-color: #3b3b3b;
  transition: all 0.3s ease-in-out;
}

.popup-card .cross-icons {
  height: 32px;
  width: 32px;
  position: absolute;
  top: 16px;
  right: 22px;
  color: red;
  border: 1px solid#a7a2a281;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

/* .popup-card .cross-icons:hover{
  transform: rotate(10deg);
} */

/* -------------table footer and pagination ------------- */

.pegination {
  text-align: center;
  padding: 10px;
  background-color: #f1eeee;
  /* padding: 10px;  */
  position: fixed; /* Fix the position */
  bottom: 0; 
  /* left: 0;  */
  width: 100%; 
}

.pegination .peginationinner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.pegination .peginationinner .arrowPreNext {
  width: 24px;
  height: 24px;
  background-color: #e0e0e0;
  border-radius: 50%;
  color: #393939;
  cursor: pointer;
}

.pegination .peginationinner span {
  font-size: 12px;
}

/* ################################ AUGUST CSS FOR LEAD FORM AND LIST ############################## */
/* hover */
#btnContainer {
  width: fit-content;
}

.contact-action-container {
  /* width: 100%; */
  background-color: #FFFFFF;
  /* box-shadow: 5px 5px 5px 0 #1877F245; */
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  z-index: 999;
}

.contact-action-inner {
  display: inline-flex;
}

.action-btn-container {
  color: #1877F2;
  cursor: pointer;
  padding: 3px;
}

.action-btn-container:hover {
  background-color: #1877F245;
  border-radius: 5px;
}

.notes-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000081;
  z-index: 9999;
}

.notes-overlay-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notes-form-container {
  background-color: #FFFFFF;
  padding: 10px;
  border-radius: 10px;
  min-height: 100px;
  display: flex;
  gap: 10px;

}

.notes-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.note-input-box {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 3px;
}

.note-label {
  font-size: 14px;
  color: #393939;
}

.note-inchar {
  width: 300px;
  border-radius: 5px;
  border: none;
  outline: 1px solid #D9D9D9;
  padding: 10px;

}

.note-text {
  min-height: 70px;
}

.note-inchar:focus {
  outline: 1px solid #1877F2;
  border-radius: 5px;
}

.note-save-btn {
  padding: 10px;
  border: none;
  background-color: #1877F2;
  color: #FFFFFF;
  border-radius: 2px;
}

.note-save-btn:hover {
  cursor: pointer;
  background-color: #0e5dc5;
}

.title-note {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.close-notes {
  position: absolute;
  top: -23px;
  right: -23px;
  color: #f32222;
  background-color: #faa7a7;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #FFFFFF;
  color: #1877F2;
  position: absolute;
  padding: 10px;
  border-radius: 5px;
  bottom: -1.6em;
  left: 0;
  bottom: -35px;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #1877F245;
  opacity: 0;
  border: 1px solid #1877F2;
  z-index: 99999;
  visibility: hidden;
}

[data-title] {
  position: relative;
}

.deal-value {
  /* border: 1px dashed #1877F2; */
  padding: 5px;
}

.rightCloseArrow {
  rotate: -90deg;
  transition: all 0.5s linear;
}

.downCloseArrow {
  rotate: 0deg;
  transition: all 0.5s linear;
}

.dropdown-container {
  position: absolute;
  top: 100%;
  right: -50%;
  left: -50%;
  bottom: -200px;
  z-index: 9999;
}

.dropdown-inner {
  width: 100%;
  /* height: 100%; */
  background-color: #FFFFFF;
  box-shadow: 5px 5px 5px 0 #1877F245;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  overflow: hidden;
}

.list-container {}

.status-iem {
  padding: 5px;

}

.status-iem:hover {
  background-color: #1877F245;
}

/* ####################### LEAD FORM ############################ */
.inner-left-container {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #D9D9D9;
  padding-right: 10px;
}

.general-info-container {
  display: grid;
  grid-template-columns: 45% 45%;
  gap: 10px;
  justify-content: space-between;
}

.inner-right-container {}

.address-info-container {
  display: flex;
  flex-direction: column;
}

.other-info-container {
  padding: 10px;
}

.inner-other-info-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.delete-button {
  color: #e42c2c;
}

.whatsapp-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 620px;
  justify-content: space-between;
}

.whatsapp-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.main-container-for-whatsapp {
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 10px;
}

.whatsapp-list-item-container {
  border: 1px solid #1877F2;
  border-radius: 5px;
  width: 300px;
  gap: 10px;
  font-size: 14px;
  color: #393939;
  height: 130px;
  position: relative;
}
.whatsapp-list-item-focus-container{
  width: 300px;
  display: flex;
  justify-content: space-between;
  height: 130px;
  white-space: wrap;
}

.whatsapp-btn-container {
  background-color: #1877F259;
  color: #FFFFFF;
  border-radius: 0 5px 0 5px;
  height: fit-content;
  padding: 5px;
}
.whatsapp-message-to-send{
  position: absolute;
  top: 5px;
  right: 5px;
  left: 5px;
  bottom: 5px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}
.select-btn{
  background-color: #1877F2;
  color: #FFFFFF;
  border-radius: 5px;
  height: fit-content;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.recent-note-list{
  padding: 5px;
  border-left: 3px solid #ffa500;
  border-radius: 3px;
  background-color: #ffa50029;
  width: 280px;
}
#ArrowBackIosNewOutlinedIcon{
  font-size: 12px;
}

.comment-btn-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 5px 0;
  color: #1877F2;
}
.comment-btn{
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.comments-container{
  padding: 5px 5px 5px 15px;
  font-size: 13px;
  color: #595959;

}
/* .note-list{
  max-height: 400px;
  overflow: auto;
} */
/* .comments-conatiner-display-block{
  display: block;
  animation: heightSmoother 5s;
}
.comments-conatiner-display-none{
  display: none;
  animation: heightNoneSmoother 5s;
}
@keyframes heightSmoother{
  from{
    height: 0;
    overflow: hidden;
  }
  to{
    height: 100%;
    overflow: auto;
  }
}
@keyframes heightNoneSmoother{
  from{
    height: 100%;
    overflow: hidden;
  }
  to{
    height: 0;
    overflow: hidden;
  }
} */
.comments-list-row{
  border-radius: 3px;
  border-left: 3px solid #1877F2;
  padding: 0 5px;
  margin-bottom: 5px;
  background-color: #F9F9F9;
}
.comment-input-area{
  max-width: 260px !important;
  height: 60px;
  width: 100%;
}
.comment-action-btns{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

}
.WhatsAppArea{
  border: none !important;
  outline: none !important;
  min-width: 80% !important;
  height: auto !important;
}
/* css for popup */
.ticketReply{
  padding: 10px;
}
.ticketHeader{
  text-align: center;
  font-size: 24px;
  color: #1976D2;
}
.ticketDetailsGrid{
  border: 1px solid #dbd8d8;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 60% auto;
}
.leftTicket{
  border-right: 1px solid #dbd8d8;
  padding-right: 10px;
}
.rightTicket{
  padding-left: 10px;
}
.ticketTitle{
  text-align: center;
  color: #69696a;
  font-size: 16px;
  font-weight: 500;
}
.deatilsGridForTicket{
  color: #69696a;
  display: grid;
  grid-template-columns: 30% 10px auto;
  font-size: 14px;
  gap: 5px;
  padding: 10px 0;
}
.ticketTite{
  font-weight: 500;
}
.subjectTicket{
  font-size: 14px;
  gap: 5px;
  color: #69696a;
  font-weight: 500;
  margin-top: 10px;
}
.ticketNames{
  font-size: 13px;
  color: #69696a;
}
.chitChatList{
  max-height: 590px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.ticketMessageBox{
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #1877F224;
  padding: 5px;
  gap: 5px;
}
.ticketText{
  margin-top: 10px;
  padding: 10px;
  border: none;
  outline: 1px solid #bfbfc2;
  width: 100%;
  border-radius: 5px;
  min-height: 100px;
}
.ticketText:focus{
  padding: 10px;
  border: none;
  width: 100%;
  border-radius: 5px;
  outline-color:#1877F2;
  caret-color: #1877F2;
  color: #656565;
}
.sendBtnTicket{
  font-size: 16px;
  border: none;
  color: #1877F2;
  background-color: transparent;
  display: flex;
  justify-content: right;
  align-items: center;
  cursor: pointer;
  background-color: #1877F224;
  padding: 3px 10px;
  border-radius: 40px;
}
.ticketResponseSection{
  display: flex;
  flex-direction: column;
  max-height: 550px;
  overflow-y: auto;
  gap: 10px;
}
.replyRow{
  /* padding: 5px; */
  text-align: left;
  border: 1px solid #ceced0;
  border-radius: 10px;
  /* overflow: hidden; */
}
.meassgeHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  font-size: 14px;
  background-color: #1877F224;
  color: #1877F2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.ticketContent{
  color: #656565;
  font-size: 13px;
  padding: 10px 5px;
}
.personNameTicket{
  display: inline-flex;
  align-items: center;
  gap: 5px;
}
.messageTimeTicket{
  display: inline-flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
}
.replyRowRight{
  padding: 5px;
  text-align: right;
}
.ticketResponseHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.closeTicket button{
  background-color: #c03d3d;
  border: 3px solid #FFFFFF;
  outline: 1px solid #c03d3d;
  border-radius: 40px;
  padding: 3px 10px;
  color: #FFFFFF;
  cursor: pointer;
}
.leftReply{
  background-color: #1877F2;
  color: #FFFFFF;
  font-size: 12px;
  padding: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: inline-flex;
  gap: 5px;
}
.rightReply{
  background-color: #1877F2;
  color: #FFFFFF;
  font-size: 12px;
  padding: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: inline-flex;
  gap: 5px;
}
.replyTimeTicket{
  color: #c6c3c3;
  font-size: 11px;
}
.newTicket{
  color: #04a33e;
}
.pendingTicket{
  color: #ba7704;
}
.closedTicket{
  color: #a00505;
}

/* Popup */

/* .customers_page{
  position: relative;
} */
.assignPopup{
  position: absolute;
  top: -9999px;
  right: 0;
  bottom: 9999px;
  left: 0;
  background-color: #00000042;
}
.assignPopupShow{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #00000042;
  z-index: 999999;
}
.assignPopupInner{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.containerTicket{
  background-color: #FFFFFF;
  padding: 10px;
  border-radius: 10px;
  position: relative;
}
.cutAssign{
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #1877F224;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a00505;
  font-size: 18px;
}
.cutAssign:hover{
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #a0050524;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a00505;
  font-size: 18px;
}
.assign_heading{
  color: #1877F2;
  padding-bottom: 10px;
}
.ticketDetailsContainer{
  min-width: 400px;
  /* padding-top: 14px; */
}
.chooseEmployeeSection{
  padding-top: 14px;
}
.selectEmployee{
  padding: 10px;
  border: none;
  outline: 1px solid #c6c3c3;
  font-size: 14px;
  color: #656565;
  background-color: transparent;
  border-radius: 5px;
  min-width: 200px;
}
.selectEmployee:focus{
  padding: 10px;
  border: none;
  outline: 1px solid #1877F2;
  font-size: 14px;
  color: #656565;
  background-color: transparent;
  border-radius: 5px;
  min-width: 200px;
}
.assignBtn{
  padding: 10px 0 0 0;
  text-align: center;
}
.assignBtn button{
  padding: 5px 10px;
  background-color: #1877F2;
  border: 3px solid #FFFFFF;
  border-radius: 40px;
  outline: 1px solid #1877F2;
  color: #FFFFFF;
  font-size: 14px;
  width: 50%;
}
.messageBoxTicket{
  display: flex;
  justify-content: space-between;
  padding: 10px;
  gap: 10px;
}
.ssContainer img{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
}
.coreImageTicket{
  padding: 10px 5px 10px 5px;
}
.coreImageTicket img{
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.attachResponse input{
  
}

/* .reAssignment{
  position: absolute !important;
  left: 0;
  background-color: #3cca43 !important;
  border: none;
  color: #FFFFFF;
  padding: 5px;
  border-radius: 5px;
  top: -5px;
  cursor: pointer;
  opacity: 0;
}  */

.assignTickets{
  background-color: #1877F2 !important;
  border: none;
  color: #FFFFFF;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.assignTickets:hover{
  background-color: #1877F2;
  border: none;
  color: #FFFFFF;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

/* Re Assign btn */

.reAssignment{
  position: absolute;
  left: 0;
  background-color: #3cca43 !important;
  border: none;
  color: #FFFFFF;
  padding: 3px;
  border-radius: 5px;
  top: -15px;
  cursor: pointer;
  opacity: 1;
}

.whatsapp-list {
  display: flex;
  flex-wrap: wrap; /* Allows cards to wrap onto the next line */
  max-height: 400px; /* Adjust based on your layout */
  overflow-y: auto; /* Enables vertical scrolling */
  border: 1px solid #ccc; /* Optional: adds a border */
  padding: 10px; /* Optional: adds padding */
  border-radius: 5px; /* Optional: rounds the corners */
}

.whatsapp-list-item-container {
  flex: 0 0 48%; /* Each card takes up approximately 48% of the container width */
  max-height: 150px; /* Adjust based on your layout */
  overflow-y: auto; /* Enables vertical scrolling for individual cards */
  border: 1px solid #eaeaea; /* Optional: adds a border to each card */
  margin-bottom: 10px; /* Space between cards */
  margin-right: 2%; /* Space between cards in the same row */
  padding: 10px; /* Padding inside each card */
  border-radius: 5px; /* Rounds corners of each card */
  background-color: #f9f9f9; /* Optional: background color for cards */
}

/* Remove margin from the last item in each row */
.whatsapp-list-item-container:nth-child(2n) {
  margin-right: 0; /* Remove margin from the right for every second item */
}

/* Styles for template header */
.template-header {
  font-weight: bold; /* Make header bold */
  font-size: 16px; /* Adjust font size as needed */
  color: #333; /* Header text color */
  margin-bottom: 5px; /* Space below the header */
}

/* Styles for template footer */
.template-footer {
  font-weight: bold; /* Make footer bold */
  font-size: 14px; /* Adjust font size as needed */
  color: #666; /* Footer text color */
  margin-top: 5px; /* Space above the footer */
}


.whatsapp-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button-group {
  display: flex;
  gap: 10px; /* Adjust spacing between buttons */
}

.whatsapp-button {
  background-color: #1877F2;
  border-radius: 5px;
  padding: 5px;
  border: none;
  color: #FFFFFF;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}


.animated-alert {
  animation: border-animation 5s infinite alternate;
  border: 2px solid transparent; /* Default transparent border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px; /* Padding inside the alert */
  background-color: #15b838; /* Light background color */
}

@keyframes border-animation {
  0% {
      border-color: transparent;
  }
  50% {
      border-color: #b5d6fd; /* Green border */
  }
  100% {
      border-color: #b5d6fd;
  }
}

/* loading refresh */

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-icon {
  animation: spin 1s linear infinite;
}
