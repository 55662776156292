.home_page {
    display: flex;
    /* width: 100vw; */
}

.nav_outlet {
    width: 100%;
    /* background-color: aqua; */
}

/* Sidebar css start */
.sidebar_main {
    background-color: #f5f5f5;
    width: 200px;
    transition: all 0.5s;
    height: 100vh;
    color: #393939;
}

.hideSidebar {
    width: 37px;
    transition: all 0.s;
}

.sidebar_inner {
    width: 100%;
}

.logo_container {
    width: 100%;
    height: 40px;
    background-color: #1976D2;
    border-bottom: 1px solid #d6d6d6;
}

.logo_side {
    padding: 5px;
}

.logo_image {
    width: 100%;
}

.sidebar_menu_container {
    width: 100%;
}

.sidebar_menu_container a:hover {
    /* color: #fc8626 !important; */
    cursor: pointer;
    /* background-color: #a9afaf; */
    transition: all 0.5s ease-in-out;
}
/* .sidebar_menu_container a {
    cursor: pointer;
    background-color: #a9afaf;
    transition: all 0.5s ease-in-out;
} */

.menu_item {
    width: 100%;
}

.menu_inner {
    display: flex;
    gap: 10px;
    align-items: center;
    text-decoration: none;
    color: #393939;
    padding: 10px;
    transition: all 0.5s ease-in-out;
    position: relative;

}

.active_menu {
    cursor: pointer;
    background-color: #d2d2d2;
    /* transition: all 0.5s ease-in-out; */
}

.menu_inner:hover {
    cursor: pointer;
    background-color: #d0d0d0;
    transition: all 0.5s ease-in-out;
}

.menu_inner span {
    /* background-color: #d6d6d6; */
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    /* font-weight: 500; */
}

.menu_txt_hide {
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: all 0.5s;
    font-size: xx-small;
    overflow-x: hidden;
}

.menu_inner .arrow {
    position: absolute;
    right: -4px;
    font-size: 24px;
    transition: all 0.3s;
}

.menu_inner .arrowHide {
    rotate: 90deg !important;
}

.expandable {
    background-color: #FFFFFF;

    transition: all 1s;
}

.expandable .menu_inner {
    color: #393939 !important;
}

.expandable .menu_inner:hover {
    color: #393939 !important;
}

/* sidebar css end */
/* navbar css start */
.navbar_main {
    background-color: #1976D2;
    height: 40px;
    border-bottom: 1px solid #d6d6d6;
}

.nav_inner {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    color: #fdfdfd;
}

.hamburger {
    font-size: 24px;
    cursor: pointer;
}

.nav_right {
    display: flex;
    align-items: center;
    gap: 10px;
}

.profile_container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
}

.profile_picture {
    width: 25px;
    height: 25px;
    border-radius: 50%;

}

.profile_card_none {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -390000px;
    transition: all 0.5s;
}

.profile_card {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 39px !important;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.5s;
    background-color: #20202067;
}

.card_none {
    display: none;
}

.card_block {
    display: block !important;
}

.notification_counter {
    font-size: 11px;
    background-color: #fc8626;
    padding: 5px;
    font-weight: 500;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 8px;
}

@media print {

    .navbar_main {
        display: none;
    }
}

.profile_section {
    overflow: auto;
    max-height: 72vh;
}