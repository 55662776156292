.settingBannerContainer {
  width: 100%;
  min-height: 100px;
  background-color: #1877f2;
  display: flex;
  align-items: center;
  padding: 14px;
}

.setHeading {
  font-size: 24px;
  color: #ffffff;
}

.settingContainer {
  position: relative;
}

/* .settingDetailsSide {
    width: 100%;
} */

.accountBox {
  position: absolute;
  top: 80px;
  left: 50px;
  right: 50px;
  min-height: 530px;
  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
}

.accountContainer {
  width: 100%;
  height: 100%;
  padding: 14px;
  display: flex;
  gap: 10px;
}

.settingMenuSide {
  min-width: 200px;
  border-right: 1px solid #f1f1f1;
}

.activeMenuSetting {
  color: #1877f2 !important;
}

.imenuSide {
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.settingImage {
  width: 100px;
}

.settingImage img {
  border-radius: 50%;
  padding: 5px;
  /* width: 98%; */
  border: 1px solid #c9c9c9;
}

.userName {
  color: #373737;
  font-size: 18px;
}

.smenusContainer {
  display: flex;
  flex-direction: column;
}

.menusSetting {
  padding: 14px;
  border-bottom: 1px solid #f1f1f1;
  color: #575757;
}

.menusSetting:hover {
  padding: 14px;
  border-bottom: 1px solid #f1f1f1;
  color: #1877f2;
  cursor: pointer;
  background-color: #f1f1f1;
}

/* *******************RESET PASSWORD**************************** */
.reset-pwd-form {
  display: flex;
  /* justify-content: center;
    align-items: center; */
  flex-direction: column;
  gap: 20px;
  width: 300px;
}

.reset-pwd-form .reset-actionbtns-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
  gap: 10px;
}

.reset-pwd-form .reset-actionbtns-wrapper .actionBtns {
  margin-bottom: 20px;
  font-size: 12px;
}

.reset-pwd-form .content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.content-wrapper .reset-input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.content-wrapper .reset-input-wrapper .label {
  font-family: system-ui;
  margin-bottom: 3px;
  color: #595959;
  position: relative !important;
  left: 0 !important;
  top: 0 !important;
  font-weight: 500 !important;
}

.content-wrapper .reset-input-wrapper input {
  height: 40px;
  background: transparent;
  border-radius: 7px;
  outline: none;
  border: 1px solid #535155;
  padding: 0 8px;
}

/* ##########################PLAN SETTING################################# */
.priceHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.pricing-form-container {
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  padding: 10px;
  margin-top: 14px;
}

.country-code-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  font-size: 16px;
}
.select-country-code {
  width: 250px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}
.country-drop-plan {
  width: 70%;
  padding: 5px;
  border-radius: 5px;
  background-color: transparent;
  border: none;
  outline: 1px solid #bcbbbb;
  color: #323033;
}
.plan-input-field {
  width: 250px;
  padding: 10px 5px;
  border-radius: 5px;
  background-color: transparent;
  border: none;
  outline: 1px solid #bcbbbb;
  color: #323033;
}
.plan-input-field:focus {
  padding: 10px 5px;
  border-radius: 5px;
  background-color: transparent;
  border: none;
  outline: 1px solid #1877f2;
  color: #323033;
}
.select-country-code span {
  padding: 5px;
  border-radius: 5px;
  background-color: transparent;
  border: none;
  outline: 1px solid #bcbbbb;
  color: #323033;
  min-width: 70px;
  text-align: center;
}
.all-pricing-container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px 30px;
  padding: 10px 0;
  justify-content: space-between;
}
.plan-label-head {
  font-size: 14px;
  font-weight: 600;
  color: #444343;
}
.plan-button-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px;
}
.plan-submit-button {
  padding: 7px 10px;
  border-radius: 5px;
  background-color: #1877f2;
  color: #ffffff;
  border: none;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
}
.plan-cancel-button {
  padding: 7px 10px;
  border-radius: 5px;
  background-color: #f93a3a;
  color: #ffffff;
  border: none;
  font-weight: 500;
  letter-spacing: 1px;
  cursor: pointer;
}

/* -------------------------- PlanSetting and addAmount CSS --------------------- */
.pricing-dialog-title {
  padding: 5px 10px;
  font-size: 18px;
}
.pricing-dialog-main {
  min-width: 100px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
}
.pricing-dialog-main .select-wrapper {
  width: 100%;
  display: flex;
  gap: 20px;
}
.select-wrapper .select-main {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  gap: 5px;
}
.select-main label {
  font-size: 12px;
}
.select-main .pricing-select {
  width: 130px;
  border: none;
  outline: solid 1px #9a9a9a;
  border-radius: 5px;
  padding: 5px;
  background-color: #ffffff;
}
.pricing-mini-heading {
  width: 100%;
  display: flex;
  align-items: center;
  color: rgb(110, 102, 102);
}
.pricing-mini-heading .text {
  font-size: 14px;
  width: 20%;
}
.pricing-mini-heading .line {
  height: 2px;
  width: 80%;
  background: #b9b3b3;
}
.pricing-section-main{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
}
.pricing-section-main .mini-section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.pricing-section-main .mini-section input {
    max-width: 80px;
    padding: 3px 10px;
    outline: none;
    border: 1px solid gray;
    border-radius: 3px;
}

.pricing-dialog-main .button-wrapper{
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
}
.button-wrapper button{
    padding: 5px 10px;
    outline: none;
    border: 1px solid gray;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
}

.headingComponentsForPlan {
  font-size: 24px;
  color: #494949;
  display: flex;
  justify-content: space-between; /* Aligns items to the left and right */
  /* align-items: center; Vertically centers the items */
  gap: 10px; /* You can keep this for spacing if needed */
}