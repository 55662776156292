@import url('https://fonts.googleapis.com/css2?family=Muli&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.sub_start {
    width: 100%;
    height: auto;
    padding: 60px 20px;
    margin: auto;
}

.sub_start .sub_part {
    width: 100%;
    height: auto;
    margin: auto;
}

.sub_part .sub_heading h2 {
    text-align: center;
    font-size: 30px;
    letter-spacing: 1px;
    color: #393939a8;
    font-family: 'Muli', sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
}

.sub_part .subs_all_plan {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    flex-wrap: wrap;
}
.plan_type{
    display: flex;
    width: 100%;
    font-size: 24px;
    color: #6d5a9b;
    text-transform: uppercase;
    font-weight: 600;
    justify-content: center;
}
.subs_all_plan .sub_plan {
    padding: 20px 0px;
    border: 1px solid #37373733;
    border-radius: 20px;
    margin: auto;
    width: 270px;
    background-color: rgba(60, 60, 60, 0.06);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
}

.sub_plan .box {
    width: 144px;
    height: 94px;
    background-color: rgb(76, 175, 80);
    position: absolute;
    top: -29px;
    rotate: 45deg;
    right: -58px;
}
.first_headings{
    background-color: #FFFFFF;
    padding: 10px;
    display: flex;
    justify-content: center;
    font-size: 24px;
    text-transform:uppercase ;
    color: #2a6842;
    font-weight: 700;
}

/* .sub_plan .first_headings {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    background-color: #FFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
} */

/* .sub_plan .first_headings .f_head h1 {
    width: 100%;
    height: auto;
    text-align: center;
    padding: 20px 30px;
    font-family: 'Muli', sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
    color: #393939;
    font-weight: 600;
} */

.sub_plan .para {
    padding: 20px 10px;
    line-height: 25px;
}

.para .second_line {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 0;
}

.second_line .right_img {
    color: #1d5324;
    font-size: 18px;
}

.second_line .second_content{
    color: #2e2d2d;
    font-family: 'Muli', sans-serif;
    font-size: 14px;
    font-weight: 600;
}
.description{
    font-size: 12px;
    color: #2e2d2d;
}
.payment_type {
    width: 100%;
    height: auto;
}

.payment_type .first_pay h1 {
    font-family: 'Muli', sans-serif;
    text-align: center;
    font-size: 30px;
    color: #393939d1;
    font-weight: 600;
}

.payment_type .mnth p {
    text-align: center;
    color: #37373796;
}

.select {
    width: 100%;
    height: auto;
    margin-top: 20px;
    text-align: center;
}

.select .last_select {
    width: 100%;
    height: auto;
}

.select .last_select button {
    width: 50%;
    height: auto;
    padding: 15px 0px;
    border-radius: 40px;
    border: none;
    background-color: #FFFFFF;
    transition: all 1.2s;
    letter-spacing: 1px;
    font-family: 'Muli', sans-serif;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
    border: 1px solid rgb(76, 175, 80);
}

.select .last_select button:hover {
    background-color: rgb(76, 175, 80);
    color: #FFFFFF;
    border: 1px solid white;
}

/* media query start here=====>>>>>>>>>>>>> */
@media (max-width:320px) {
    .sub_start {
        padding: 10px;
    }

    .sub_part .sub_heading h2 {
        font-size: 20px;
    }

    .sub_part .subs_all_plan {
        display: block;
        margin-top: 5px;
    }

    .subs_all_plan .sub_plan {
        padding: 10px 2px;
        margin: 10px 0;
    }

     .name_head{
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
  .name_head .name_ab {
        padding: 10px 30px !important;
        font-size: 24px !important;
    }

    .sub_plan .para {
        padding: 8px;
        line-height: 20px;
    }

    .second_line .right_img img {
        margin: 0;
    }

    .payment_type .first_pay h1 {
        font-size: 20px;
    }

    .payment_type {
        margin-top: 0;
    }

    .payment_type .mnth p {
        font-size: 13px;
    }

    .select .last_select button {
        width: 37%;
        padding: 10px 0;
        font-size: 11px;
    }

    .select {
        margin-top: 10px;
    }

    .sub_plan .box {
        width: 124px;
        height: 85px;
    }

}

@media (min-width:320px) and (max-width:480px) {
    .sub_start {
        padding: 10px;
    }

    .sub_part .sub_heading h2 {
        font-size: 20px;
    }

    .sub_part .subs_all_plan {
        margin-top: 5px;
    }

    .subs_all_plan .sub_plan {
        padding: 10px 2px;
        margin: 10px 0;
    }

    /* .sub_plan .first_headings .f_head h1 {
        padding: 10px 38px;
        font-size: 13px;
    } */

    .payment_type {
        margin: 0;
    }

    .sub_plan .para {
        padding: 8px;
        line-height: 21px;
    }

    .second_line .right_img img {
        margin: 0;
    }

    .payment_type .first_pay h1 {
        font-size: 20px;
    }

    .payment_type .mnth p {
        font-size: 13px;
    }

    .select .last_select button {
        width: 37%;
        padding: 10px 0;
        font-size: 11px;
    }

    .select {
        margin-top: 10px;
    }

    .sub_plan .box {
        width: 119px;
        height: 83px;
    }
}

@media (min-width:481px) and (max-width:680px) {
    .sub_start {
        padding: 10px;
    }

    .sub_part .sub_heading h2 {
        font-size: 20px;
    }

    .sub_part .subs_all_plan {
        margin-top: 5px;
    }

    .subs_all_plan .sub_plan {
        padding: 10px 2px;
        margin: 10px 0;
    }

    /* .sub_plan .first_headings .f_head h1 {
        padding: 10px 67px;
        font-size: 13px;
    } */

    .payment_type {
        margin: 0;
    }

    .sub_plan .para {
        padding: 8px;
        line-height: 21px;
    }

    .second_line .right_img img {
        margin: 0;
    }

    .payment_type .first_pay h1 {
        font-size: 20px;
    }

    .payment_type .mnth p {
        font-size: 13px;
    }

    .select .last_select button {
        width: 37%;
        padding: 10px 0;
        font-size: 11px;
    }

    .select {
        margin-top: 10px;
    }

    .sub_plan .box {
        width: 123px;
        height: 72px;
    }
}

@media (min-width:681px) and (max-width:767px) {
    .sub_start {
        padding: 10px;
    }

    .sub_part .sub_heading h2 {
        font-size: 20px;
    }

    .sub_part .subs_all_plan {
        margin-top: 5px;
    }

    .subs_all_plan .sub_plan {
        padding: 10px 2px;
        margin: 10px 0;
    }

    /* .sub_plan .first_headings .f_head h1 {
        padding: 10px 42px;
        font-size: 13px;
    } */

    .payment_type {
        margin: 0;
    }

    .sub_plan .para {
        padding: 8px;
        line-height: 21px;
    }

    .second_line .right_img img {
        margin: 0;
    }

    .payment_type .first_pay h1 {
        font-size: 20px;
    }

    .payment_type .mnth p {
        font-size: 13px;
    }

    .select .last_select button {
        width: 37%;
        padding: 10px 0;
        font-size: 11px;
    }

    .select {
        margin-top: 10px;
    }

    .sub_plan .box {
        width: 135px;
        height: 70px;
    }
}

@media (min-width:768px) and (max-width:1024px) {
    .sub_start {
        padding: 10px;
    }

    .sub_part .sub_heading h2 {
        font-size: 20px;
    }

    .sub_part .subs_all_plan {
        margin-top: 5px;
    }

    .subs_all_plan .sub_plan {
        padding: 10px 2px;
        margin: 10px 0;
    }

    /* .sub_plan .first_headings .f_head h1 {
        padding: 10px 55px;
        font-size: 13px;
    } */

    .payment_type {
        margin: 0;
    }

    .sub_plan .para {
        padding: 8px;
        line-height: 21px;
    }

    .second_line .right_img img {
        margin: 0;
    }

    .payment_type .first_pay h1 {
        font-size: 20px;
    }

    .payment_type .mnth p {
        font-size: 13px;
    }

    .select .last_select button {
        width: 37%;
        padding: 10px 0;
        font-size: 11px;
    }

    .select {
        margin-top: 10px;
    }

    .sub_plan .box {
        width: 119px;
        height: 74px;
    }
}