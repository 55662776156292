
/* fgdfs */
.subs-main {
  padding: 10px;
}

.section-title {
  padding: 10px 0 10px 0;
  font-size: 16px;
  font-weight: 500;
}
.ttl_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ttl_flex .input-main {
  margin: 0;
}
.subs-inner {
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px;
  height: auto;
}
.new-subs-btn {
  font-size: 12px !important;
  /* margin-bottom: 10px; */
}

.tag-title {
  font-size: 16px;
  font-weight: 600;
  color: rgb(75, 72, 72);
  padding: 10px 0;
}
.subs-card-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.table-div {
  margin-top: 50px;
}
.cross-subs{
  height: 32px;
  width: 32px;
  color: red;
  border: 1px solid#a7a2a281;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
/* ----------subscription table ----------- */
@media screen and (max-width: 720px) {
  .subs-table {
    overflow-x: scroll;
  }
}

.subs-table table {
  width: 100%;
  border-collapse: collapse;
  min-width: 720px;
}
.subs-table table thead tr {
  background-color: #f1eeee;
}
.subs-table table thead tr th {
  padding: 10px 5px;
  color: #393939;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  border-bottom: 1px solid #d4d3d3;
}
.subs-table table tbody tr td {
  font-size: 12px;
  color: #393939;
  padding: 10px 5px;
  border-bottom: 1px solid #f3f2f2;
  height: 30px;
  text-align: left;
}
.subs-title {
  font-size: 14px !important;
  color: #393939 !important;
}
.icons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.icons-wrapper .icons {
  height: 20px;
  width: 20px;
  color: rgb(7, 61, 131);
  cursor: pointer;
}

.popup-main {
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-card {
  height: auto;
  width: auto;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.ongoing_popup {
  position: absolute;
  background-color: #00000081;
  top: 0 !important;
  left: 0;
  right: 0;
  bottom: 0 !important;
  z-index: 9;
}
.ongoing_popup_hide {
  position: absolute;
  background-color: #00000081;
  top: -1000px;
  left: 0;
  right: 0;
  bottom: 2000px;
  z-index: 9;
  transition: all 1s;
  /* height: 0px; */
}
.ongoing_inner {
  /* background-color: #599ffa; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup_ttl {
  color: #727171;
  font-weight: 600;
}

/* // imported css  */
.features-list-bottom{
  display: grid;
  grid-template-columns: 15px auto 20px;
  gap: 3px;
  align-items: center;
  font-size: 12px;
}
.features-pencil{
  font-size: 12px !important;
  color: #1877F2;
  cursor: pointer;
}
.fields-wrapper textarea {
  outline: none;
  height: 60px;
  width: 200px;
  border-radius: 4px;
  padding: 4px;
}
.fields-wrapper p {
  margin: 0 !important;
  padding: 0 !important;
  gap: 0;
  font-size: 14px;
  color: gray;
}
.fields-wrapper .input-main {
  margin-top: 0 !important;
  width: 200px;
}
.content-wrapper {
  display: flex;
  /* gap: 15px; */
  flex-direction: column;
}
.inputWrapper {
  display: flex;
  gap: 6px;
  align-items: center;
}
.inputWrapper p {
  /* margin-top: 13px; */
  font-size: 14px;
  color: gray;
}
.inputWrapper input {
  height: 17px;
  width: 27px;
  outline: none;
}
.allInputwrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  padding: 10px;
}
.saveBtn {
  border-radius: 5px !important;
  outline: none;
  width: auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.cancelBtn {
  width: auto;
  background-color: rgb(230, 78, 78) !important;
  outline: none;
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;

}
.btn-wrapper {
  display: flex;
  justify-content: space-between;
  width:  225px;
}
.feature-column {
  width: 233px;
  margin-top: 10px;
  font-size: 16px;
  color: #6f767c;
}
.select-plans {
  width: 200px;
  height: 30px;
  outline: none;
  border-radius: 3px;
}
select option {
  /* background-color: rgb(73, 72, 72); */
  background-color: rgb(54 52 52);
}
.feature-items {
  width: 210px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.feature-items .not-selected-p {
  font-size: 13px;
  padding: 1px;
  color: #e15050;
  width: 100%;
}
.feature-items span {
  font-size: 15px;
  color: #393939;
}
.start-icon {
  color: rgb(14, 71, 14);
}
.dual-item-wrapper {
  display: flex;
  width: 100%;
  padding: 5px;
  gap: 10px;
  align-items: flex-end;
}
.features-row{
  align-items: start !important;
}
.create-subs-title{
  font-size: 22px;
  width: 100%;
  padding: 1px 12px;
  color: #565050;
  font-weight: 500;
  text-align: center;
}
.popup-main .popup-card form{
  margin-top: 20px;
}
.popup-card{
  height: auto;
  width: auto;
  padding: 20px;
  border-radius: 8px;
  display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.popup-card2{
  height: 100% !important;
  width: 100% !important;
  padding: 20px;
  border-radius: 8px;
  display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.employeeInputs{
  gap: 10px;
  display: grid;
  grid-template-columns: auto auto;
}
.imageSupport{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #b3b0b0;
  padding: 2px;
  position: relative;
  overflow: hidden;
}
.imageSupport .proInput{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
}
.fields-wrapper .selectInput {
  min-height: 28px;
  border-radius: 5px;
  outline: none;
  border: 1px solid gray;
  padding: 3px 10px;
  min-width: 200px;
  background-color: transparent;
}
.fields-wrapper .selectInput option{
  color: #393939;
  background-color: #FFFFFF;
}
.imageSupport .proPic{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.features-box{
  display: flex;
  /* justify-content: center; */
  align-items: flex-start !important;
  height: auto;
  border: 1px solid #d8d2d2;
  border-radius: 7px;
  margin-bottom: 14px;
}
.features-box svg{
  width: 25px;
  height: 25px;
  position: absolute;
  /* top: 221px; */
  /* left: 216px; */
}
.feature_column{
  width: 180px;
}
.restricted_user_column{
  width: 180px;
}
.flex-class-2{
  display: grid;
  grid-template-columns: 45% auto;
}
.heading-permission-feature{
  font-size: 16px;
  font-weight: 500px;
  margin: 10px 0;
}