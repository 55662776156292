@import url('https://fonts.googleapis.com/css2?family=Muli&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --text-color: rgb(43, 42, 42);
  --container-bg: #fff;
  --register-btn: lightblue;
}

.container h1{
  color: #475569;
  font-size: 16px;
  text-align: left;
  padding: 10px;
}

.form-control{
  margin-top: 5px;
 border-radius: 10px;
 min-width: 225px;
}

.agent_details form{
  width: fit-content;
  height: auto;
  padding: 20px;
}

form .col_1{
  width: 100%;
  display: grid;
  grid-template-columns: 250px 250px;
  gap: 5px 10px;
}

.line_1 {
  position: relative;
  width: 100%;
}
.agent_details .form {
  display: flex;
  justify-content: space-around;
  gap: 100px;
  margin: 0;
}
form .col_1_billing{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
form .col_2_shipping {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.col_1_billing h3{
  font-size: 14px;
  color: #333;
  display: flex;
  justify-content: space-between;
}
.col_2_shipping h3{
  font-size: 14px;
  color: #333;
  display: flex;
  justify-content: space-between;
}
.form-control input{
  background-color: transparent;
  outline: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  max-width: 250px;
  padding: 10px 10px;
  font-size: 18px;
  font-size: 14px;
  caret-color: #2563EB;
  color: var(--text-color);
  border: none;
}
input:focus{
  outline: 1px solid #96bbf3;
}
.form-control select {
  width: 100%;
  max-width: 250px;
  background: transparent;
  border: none;
  height: 40px;
  border-radius: 5px;
  outline: 1px solid #ccc;
  color: #475569;
}
.form-control select option{
  color: #b7b9bd;
  border-radius: 10px;
}
.form-control select:focus {
  outline: 1px solid #96bbf3;
}
/* .form-control:focus {
  border: 3px solid #555;
} */

.login{
  width: 100%;
  height: auto;
  color: var(--text-color);
  font-family: 'Muli',sans-serif;
  margin: auto;
  overflow: hidden;
}
.login .agent_details{
  width: 100%;
  height: auto;
  margin: auto;
  background-color: #ffffff;
  border-radius: 5px;
  /* box-shadow: 5px 5px 20px 1px rgba(0, 0, 0, 0.10), -5px -5px 10px rgba(0, 0, 0, 0.10); */
}

form label{
  color: #475569;
  font-size: 12px;
  font-weight: 600;
  /* position: absolute; */
  top: -8px;
  left: 20px;
  background-color: #ffffff;
  padding: 0px 5px;
}
.form-control textarea{
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid #CBD5E1;
  border: none;
  outline: 1px solid #ccc;
}
/* textarea:focus{
  border-radius: 10px;
  outline: 1px solid #96bbf3;
  padding: 10px;
} */
/* select.form-control {
  margin: 1px 80px 15px;
  width: 74%;
  height: 40px;
  background: transparent;
} */
select option{
  color: #CBD5E1;
}
form .end_section{
  padding: 2px;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  gap: 24px;
  justify-content: right;
}

.end_section button{
  color: #fff;
  font-family: 'Muli',sans-serif;
  overflow: hidden;
  cursor: pointer;
  padding: 5px 20px;
  background: #2563EB;
/*  2563EB*/
 
  border: 0;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
}
button.btn{
  color: #475569;
  background: #fff;
  border: 1px solid #CBD5E1;
  cursor: pointer;
}
.popup-main .popup-card form .dual-item-wrapper{
  margin: 0 0 10px 0;
}
.popup-main .popup-card form .date-description-wrapper{
  display: flex;
  height: auto;
  width: auto;
  justify-content: center;
  align-items: center;
  margin: -21px 0 0 0 !important;
}
.popup-main .popup-card .btn-wrapper{
  width: 50% !important;
}