.dialog-container {
    position: relative;
    width: 500px; /* or whatever width you prefer */
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: auto;
    transition: all 0.3s ease;
  }
  
  .pricing-dialog-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
    color: #333;
  }
  
  .select-wrapper {
    margin-bottom: 15px;
  }
  
  .select-main label {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
    color: #555;
  }
  
  .pricing-select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.3s ease;
  }
  
  .pricing-select:focus {
    border-color: #3f51b5; /* MUI primary color */
    outline: none;
  }
  
  .pricing-mini-heading {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  
  .pricing-mini-heading .text {
    font-weight: 600;
    color: #333;
  }
  
  .pricing-mini-heading .line {
    flex-grow: 1;
    height: 1px;
    background-color: #ddd;
    margin-left: 10px;
  }
  
  .pricing-section-main {
    margin-top: 10px;
  }
  
  .mini-section {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .mini-section input[type="file"] {
    margin-left: 10px;
  }
  
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .button-wrapper button {
    background-color: #3f51b5; /* MUI primary color */
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 10px;
    /* margin-left: 10px; */
  }
  
  .button-wrapper button:hover {
    background-color: #303f9f; /* Darker shade for hover */
  }
  
  .button-wrapper button:last-child {
    background-color: #4caf50; /* Green for Create/Update */
  }
  
  .button-wrapper button:last-child:hover {
    background-color: #388e3c; /* Darker green for hover */
  }
  
  .error-message {
    font-size: 12px;
    color: red;
    margin-top: 5px;
  }
  