.superDash{
    padding: 10px;
}
.dashboard_page{
    width: 100%;
    height: auto;
    transition: all 0.3s;
}
.dashboard_main{
    padding: 10px;
}
.dash_cards{
    transition: all 0.3s;
}
.dash_grid{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
}
.card_main{
    background-color: #F9F9F9;
    cursor: pointer;
    box-shadow: #1877F233 0px 2px 8px 0px;
    border-radius: 5px 10px;
}
.card_inner{
    width: 200px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;
    align-items: center;
    gap: 24px;
}
.left_card{
    max-width: 150px;
}
.counter{
    font-size: 24px;
    color: #9ea1a3;
    font-weight: 500;
}
.title_text{
    font-size: 12px;
    line-height: 24px;
    color: #3788be;
}
.icon_circle{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 34px;
    color: #9ea1a3;
}
.graphical_section{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around ;
    gap: 10px;
    padding: 24px 0;
}
.graph_title{
    font-size: 14px;
    color: #9ea1a3;
    text-align: center;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}
.right_title{
    background-color: #ebeef0;
    padding: 5px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    gap: 5px;
    flex-wrap: nowrap;
    position: relative;
}