.customer_profile_up {
    border-bottom: 1px solid #dddcdc;
    margin-bottom: 10px;
}

.profile_options {
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
}

.profile_option {
    display: flex;
    gap: 5px;
    color: #393939;
    cursor: pointer;
    font-size: 14px;
    border: 1px solid #f7f5f5;
    background-color: #f7f5f5;
    padding: 5px 5px;
    border-radius: 5px;
    transition: all 0.5s;
}

.profile_option:hover {
    border: 1px solid #3b3b3b;
    background-color: #3b3b3b;
    color: #f3f1f1;
}

.customer_details_sec {
    display: grid;
    grid-template-columns: 40% auto;
    gap: 10px;
    /* display: block !important; */
}

.cust_title {
    color: #3b3b3b;
    font-size: 16px;
    padding: 5px 0;
    font-weight: 500;
}

.profile {
    display: flex;
    flex-direction: column;
    transition: all 0.3s;
}

/* .profile_img{
    display: flex;
    justify-content: center;
    position: relative;
    height: 60px;
    margin-bottom: 24px;
    background-color: #bef6fa;
    transition: all 0.3s;
}
.profile_img span{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #bef6fa;
    color: #05ebfc;
    font-size: 24px;
    font-weight: 600;
    position: absolute;
    bottom: -24px;
    border: 4px solid #FFFFFF;
    transition: all 0.3s;
} */
.personal_details {
    display: grid;
    grid-template-columns: 35% 20px auto;
    color: #3b3b3b;
    font-size: 14px;
    gap: 8px;
}

.naming_title {
    font-weight: 500;
}

.address_table table {
    width: 100%;
    border-collapse: collapse;
    transition: all 0.3s;
}

.address_table table thead tr {
    font-size: 14px;
    text-align: left;
    color: #393939;
    background-color: #f3f1f1;
    padding: 10px 3px;
    transition: all 0.3s;
}

.address_table table thead tr th {
    font-size: 14px;
    text-align: left;
    color: #393939;
    background-color: #f3f1f1;
    padding: 10px 3px;
    font-weight: 500;
    transition: all 0.3s;
}

.address_table table tbody tr td {
    padding: 15px 3px;
    color: #3b3b3b;
    font-size: 14px;
    transition: all 0.3s;
}

.address_table table tbody tr td:nth-child(1) {
    padding: 10px 3px;
    color: #393939;
    font-size: 14px;
    background-color: #F3F1F1;
    font-weight: 500;
}

/* ############ LEAD PROFILE NEW CODE ###################### */
.lead-profile-section {
    display: flex;
    min-height: 100px;
    background-color: #1877F2;
    /* justify-content: space-between; */
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border-radius: 5px;
    min-width: fit-content;
    max-width: 100%;
}

.mailTO {
    text-decoration: none;
    color: #F3F1F1;
    margin: 5px 0;
}

.mailTO:hover {
    text-decoration: underline;
    color: #F3F1F1;
    margin: 5px 0;
}

.lead-personal-details {
    display: flex;
    width: fit-content;
    gap: 10px;
}

.lead-profile-image {
    width: 24px;
}

.lead-profile-image img {
    width: 100%;
}

.other-lead-profile-inner {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.other-lead-profile-inner span:nth-child(1) {
    color: #e0dddd;
    font-weight: 400;
    font-size: 14px;
}

.other-lead-profile-inner span:nth-child(2) {
    color: #f9f9f9;
    font-weight: 400;
    font-size: 14px;
    font-weight: 500;
}

.all-details-dashboard {
    display: flex;
    padding: 5px 0;
    gap: 10px;
}

.address-description-container {
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 5px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: #1877F233 0px 2px 8px 0px;
    height: fit-content;
    border-top: 4px solid;
}

.deal-card {
    border-left: 3px solid;
    border-radius: 5px;
    padding: 10px;
    background-color: #FFFFFF;
    box-shadow: #1877F233 0px 2px 8px 0px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.deal-card-value {
    font-size: 24px;
    font-weight: 500;
    color: #2f87fc;
}

.deal-type{
    font-size: 14px;
    font-weight: 500;
    color: #595959;
}
.deal-card-date{
    font-size: 12px;
    font-weight: 500;
    color: #1877F2;
}

.description-addr-title {
    font-size: 0.90rem;
    color: #595959;
    font-weight: 400;
}

.description-addr {
    font-size: 0.90rem;
    color: #595959;
    font-weight: 500;
}

.other-dashboard-container {
    display: flex;
    gap: 10px;
}

/* --------------------------------------------------------------------------- */

.round {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 5px;
    transform: rotate(-45deg);
    width: 10px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
    background-color: #1877F2;
    border-color: #1877F2;
}

.round input[type="checkbox"]:checked+label:after {
    opacity: 1;
}