/* .create-invoice-main {
  padding: 10px;
} */
/* .section-title {
  padding: 10px 0 10px 0;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
} */

/* .create-invoice-inner {
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px;
  height: 70vh;
  width: 100%;
} */
/* .invoice-content {
  width: 80% !important;
  display: grid;
  grid-template-columns: 25% auto auto auto;
  column-gap: 20px;
} */
/* invoice table  */
/* .invoice-table{
  margin-top: 50px;
} 

 .create-invoice-table {
  width: 100%;
}
.create-invoice-table table {
  width: 100%;
  border-collapse: collapse;
}
.create-invoice-table table thead tr {
  background-color: #f1eeee;
} 
.create-invoice-table table thead tr th {
  padding: 10px 5px;
  color: #393939;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.create-invoice-table table tbody tr td {
  font-size: 12px;
  color: #393939;
  padding: 10px 5px;
  border-bottom: 1px solid #f3f2f2;
  height: 30px;
  text-align: center;
}
.invoice-create-title {
  font-size: 14px;
  color: rgb(58, 58, 202);
}
.item-wrapper {
  display: flex;
  flex-direction: column;
  width: 200px;
  gap: 3px;
  padding: 10px;
}
.item-wrapper p {
  font-size: 14px;
  color: gray;
}
.item-wrapper span {
  color: red;
  margin-right: 2px;
}
.item-long {
  width: auto;
}
.payment-div{
    display: flex;
    color: #393939;
    font-size: 14px;
    margin-top: 10px;
}
.payment-div .title{
    font-weight: 500;
} 
.payment-div .sub-text{
    font-size: 12px;
    width: 90%;
} 

.bill-div ,.ship-div{
    display: flex;
    flex-direction: column;
    color: gray;
}

.inputfield{
    width: 200px;
    height: 27px;
    margin-top: -13px;
}
.colorBtn{
    background-color: rgb(106, 106, 231);
    color: #ffffff;
    border: none;
}
.colorBtn:hover{
    background-color: rgb(106, 106, 231);
    color: #ffffff;
}*/




.create-invoice-main {
  padding: 10px;
}
.section-title {
  padding: 10px 0 10px 0;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.create-invoice-inner {
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px;
  /* height: 70vh; */
  width: 100%;
}
.invoice-content {
  width: 80% !important;
  display: grid;
  grid-template-columns: 25% auto auto auto;
  column-gap: 20px;
}
/* invoice table  */
/* .invoice-table{
  margin-top: 50px;
} */

.create-invoice-table {
  width: 100%;
}
.create-invoice-table table {
  width: 100%;
  border-collapse: collapse;
}
.create-invoice-table table thead tr {
  background-color: #f1eeee;
}
.create-invoice-table table thead tr th {
  padding: 10px 5px;
  color: #393939;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.create-invoice-table table tbody tr td {
  font-size: 12px;
  color: #393939;
  padding: 10px 5px;
  border-bottom: 1px solid #f3f2f2;
  height: 30px;
  text-align: center;
}
.invoice-create-title {
  font-size: 14px;
  color: rgb(58, 58, 202);
}
.item-wrapper {
  display: flex;
  flex-direction: column;
  width: 200px;
  gap: 3px;
  padding: 10px;
}
.item-wrapper p {
  font-size: 14px;
  color: gray;
}
.item-wrapper span {
  color: red;
  margin-right: 2px;
}
.item-long {
  width: auto;
}
.payment-div{
    display: flex;
    color: #393939;
    font-size: 14px;
    margin-top: 10px;
}
.payment-div .title{
    font-weight: 500;
} 
.payment-div .sub-text{
    font-size: 12px;
    width: 90%;
} 

.bill-div ,.ship-div{
    display: flex;
    flex-direction: column;
    color: gray;
}

.inputfield{
    width: 200px;
    height: 27px;
    margin-top: -13px;
}
.colorBtn{
    background-color: rgb(106, 106, 231);
    color: #ffffff;
    border: none;
}
.colorBtn:hover{
    background-color: rgb(106, 106, 231);
    color: #ffffff;
}

/* Create Invoice New Form */
.invoicingTitle{
  padding:  16px 0;
  font-size: 18px;
  color: #595959;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}
.formTitle{
  display: flex;
  justify-content: space-between;
}
.gridTable{
  display: grid;
  grid-template-columns: 200px auto 100px 100px 120px 120px;
  width: 100%;
  /* background-color: aquamarine; */
  /* justify-content: space-between; */
  /* gap: 10px; */
}
.gridTable .attributes{
  padding: 10px;
  /* border-top: 1px solid #c2c0c0;
  border-left: 1px solid #c2c0c0; */
  background-color: #c2c0c0;
  color: #393939;
}
.gridTable .payInput{
  /* border-left: 1px solid #c2c0c0; */
  padding: 5px;
  position: relative;
}
.disableInput{
  background-color: #adadad !important;
}
.gridTable .payInput .inputPay{
  outline: none !important;
  min-width: 60px !important;
  background-color: #f1eeee;
  width: 100%;
}
.gridTable .payInput .textPay{
  outline: none !important;
  background-color: #f1eeee;
  width: 100%;
  min-width: 100%;
}
.plusContainer{
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.plusContainer button{
  border: none;
  background-color: #1976D2;
  padding: 5px 10px;
  color: #ffffff;
  border-radius: 40px;
}
.plus{
  font-size: 24px;
  color: #1976D2;
  text-align: right;
  cursor: pointer;
}
.remove {
    position: absolute;
    right: 10px;
    top: 30%;
    color: #ff0000;
    font-size: 24px;
    cursor: pointer;
}

/* New proposal CSS part */
.subNdescription{
  display: block;
}
.subNdescription label{
  position: unset;
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #393939;
  padding-bottom: 5px;
}
.subNdescription input, textarea{
  margin-bottom: 5px;
  border: none;
  outline: 1px solid #adadad;
  padding: 5px;
  border-radius: 5px;
  min-width: 50%;
  /* max-width: fit-content; */
  font-family: inherit;
  color: #393939;
}
.subNdescription input:focus, textarea:focus{
  margin-bottom: 5px;
  border: none;
  outline: 1px solid #1877F2;
  padding: 5px;
  border-radius: 5px;
  min-width: 50%;
  max-width: fit-content;
}