

.view_heading{
    display: flex;
    justify-content: space-between;
    padding: 10px 10%;
    color: #393939;
}
.receipt_main_container{
    display: flex;
    justify-content: center;
}
.recipt_payment {
    /* width: 100%; */
    height: auto;
    /* padding: 50px 20px 0px; */
    margin: auto;
}
.receipt_section{
    width: 80%;
    /* max-width: 580px; */
    border: 1px solid #dfdede7a;
    padding: 10px;
    border-radius: 10px;
}
.receipt_handling{
    padding: 10px 0;
    border-bottom: 1px solid #e0dede;
    /* margin-bottom: 10px; */
}
.receipt_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.rec_left{
    color: #393939;
}
.rec_right{
    display: inline-flex;
    gap: 5px;
}
.rec_right span{
    width: 30px;
    height: 28px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    color: #393939;
    background-color: #dfdede;
    border-radius: 10px;
}
.recipt_payment .main_payment {
    /* width: 70%; */
    height: auto;
    /* padding: 0px 30px; */
    margin: auto;

}

.main_payment .first_side {
    /* width: 100%; */
    height: auto;
    display: flex;
    justify-content: space-between;
    /* padding: 10px 20px; */
    border-radius: 30px;
}

.main_payment .left_side {
    width: 100%;
    height: auto;
    text-align: left;
    line-height: 18px;
    /* border-bottom: 2px dashed #c1c1c17a; */
}
/* .right_side .logo_chatsense{
    width: 100%;
}
.right_side .logo_chatsense img{
    width: 30%;
} */
 .logo_chatsense img {
    width: 100%;
    height: auto;
}

 .logo_chatsense {
    width: 130px;
    height: auto;
}

 .cmpny_name h5 {
    color: #494949;
    font-family: 'Muli', sans-serif;
    font-weight: 600;
    font-size: 14px;
    margin-top: 5px;
}

.left_side .cmpny_details p {
    font-family: 'Muli', sans-serif;
    font-weight: 400;
    /* margin-top: 3px; */
    color: #393939b5;
    font-size: 12px;
}

.main_payment .right_side {
    width: 100%;
    height: auto;
    padding-bottom: 10px;
    text-align: right;
    line-height: 18px;
    /* border-bottom: 2px dashed #c1c1c17a; */
}

.right_side .company_name h3 {
    font-family: 'Muli', sans-serif;
    /* margin-top: 33px; */
    color: #181E40A3;
}

.right_side .company_details p {
    font-family: 'Muli', sans-serif;
    font-weight: 400;
    /* margin-top: 3px; */
    color: #393939b5;
    font-size: 12px;
}

.main_container {
    width: 100%;
    height: auto;
    margin: auto;
}

.main_container .second_contain {

    height: auto;
    margin: auto;
    border-radius: 30px;
}

.second_contain .payment_recipt h1 {
    text-align: center;
    margin-top: 10px;
    font-family: 'Muli', sans-serif;
    color: #39393996;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1px;
}

.second_contain .payment_mode {
    width: 100%;
    height: auto;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    border-bottom: 2px dashed #c1c1c17a;
    margin-top: 10px;
}

.payment_mode .pay_date {
    width: auto;
    height: auto;
}

.pay_date .pay_detail {
    /* padding: 10px 20px; */
    font-size: 13px;
}

.pay_detail .payDate {
    display: flex;
    justify-content: space-between;
    padding: 0px 0px 10px 0px;
    border-bottom: 1px solid #dfdede7a;
    font-family: 'Muli', sans-serif;
    color: #37373796;
    font-weight: 400;
    gap: 10px
}

.pay_detail .payMode {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    font-family: 'Muli', sans-serif;
    color: #37373796;
    font-weight: 400;
    gap: 10px;
}

.total_ammount {
    /* width: 100%; */
    height: auto;
    /* padding: 10px 20px; */
    margin: 10px auto;
    text-align: center;
    border: none;
    font-family: 'Muli', sans-serif;
    
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.total_ammount .ammountFull {
    background-color: rgb(34, 197, 94);
    padding: 10px 10px;
    border-radius: 5px;
    height: auto;
    color: #FFFFFF;
}
.inv_details{
    display: grid;
    grid-template-columns: auto 20px auto;
    text-align: left;
    font-size: 14px;
    color: #393939;
}
.inv_details .colo{
    text-align: center;
}
.terms_container{
    border-top: 1px solid #e0dede;
    padding: 5px 0;
}
.terms_heading{
    font-size: 14px;
    color: #777676;
    font-weight: 500;
}
.terms_desc{
    font-size: 12px;
    color: #777676;
    padding: 5px 0;
}
.payFor {
    /* width: 100%; */
    height: auto;
    margin: 10px auto;
    /* padding: 0px 20px; */
    font-size: 15px;
}

.payFor h2 {
    font-family: 'Muli', sans-serif;
    color: #393939c4;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1px;
}

.payFor table {
    width: 100%;
    height: auto;
    margin-top: 5px;
}

.payFor .table {
    border-collapse: collapse;
}

.payFor table tr {
    text-align: left;
}

.payFor table tr th {
    font-family: 'Muli', sans-serif;
    color: #393939c4;
    font-weight: 600;
    padding: 5px 10px;
    border: 1px solid #c1c1c17a;
    background-color: #F1EEEE
}

.payFor table tr td {
    font-family: 'Muli', sans-serif;
    color: #393939c4;
    font-weight: 400;
    border: 1px solid #c1c1c17a;
    padding: 5px 10px;
}
@media print
    {
        .receipt_handling{display: none;}
        .view_heading{display: none;}
        .receipt_section{
            width: 100% !important;
        }
    }