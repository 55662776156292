.statement {
    /* width: 100%; */
    height: auto;
    margin: auto;
}

.statement_page {
    width: 100%;
    display: flex;
    justify-content: center;
    max-height: 65vh;
    overflow: auto;
}
.statement_page_inner{
    width: 80%;
}
.statement .statement_start {
    /* width: 70%; */
    height: auto;
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding: 10px 0px;
    border-bottom: 1px solid #e1e1e196;
}

.statement_start .statment_heading h2 {
    color: #393939c9;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
}

.statement_start .cmpny_details {
    /* width: 100%; */
    margin-top: 20px;
    text-align: end;
}

.cmpny_details .cmpnyName div{
    font-size: 16px;
    font-family: 'Muli', sans-serif;
    color: #393939c9;
    font-weight: 600;
}

.cmpny_details .cmpny_para {
    padding-top: 5px;
    font-size: 13px;
    font-family: 'Muli', sans-serif;
    font-weight: 400px;
    color: #37373796;
}


/* acc_summ styling start here===>>> */
.acc_summ {
    padding: 5px 0;
    width: 100%;
    height: auto;
}

.acc_summ .acc_details {
    /* width: 70%; */
    height: auto;
    margin: auto;
    /* padding: 20px; */
    display: flex;
    justify-content: space-between;
}

.acc_details .left_side {
    width: 100%;
    height: auto;
    text-align: left;
    line-height: 20px;
}

.left_side .to div {
    font-size: 16px;
    font-family: 'Muli', sans-serif;
    color: #393939c9;
    font-weight: 600;
}

.left_side .to_para {
    padding-top: 15px;
    color: #686666;
    font-size: 14px;
}

.left_side .to_para p {
    font-size: 13px;
    font-family: 'Muli', sans-serif;
    font-weight: 400px;
    color: #37373796;
}

.acc_details .right_side {
    width: 50%;
    height: auto;
    text-align: end;
}

.right_side .right_head h2 {
    font-size: 16px;
    font-family: 'Muli', sans-serif;
    color: #393939c9;
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 1px;
}

.right_side .right_head p {
    font-size: 13px;
    font-family: 'Muli', sans-serif;
    font-weight: 400px;
    color: #37373796;
    padding-bottom: 10px;
    border-bottom: 1px solid #37373796;
}

.blnce {
    width: 100%;
    height: auto;
    margin-top: 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid #37373796;
}

.blnce .cmpny_blnce {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding: 5px 0;
    justify-content: space-between;
}

.cmpny_blnce .blnce_first {
    font-size: 13px;
    font-family: 'Muli', sans-serif;
    color: #797777;
    font-weight: 600;
    text-transform: capitalize;
}

.cmpny_blnce .blnce_second {
    font-size: 13px;
    font-family: 'Muli', sans-serif;
    font-weight: 500px;
    color: #373737;
}

.blnce .balance_due {
    width: 100%;
    height: auto;
}

.balance_due .blance_first {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    font-size: 18px;
}

.blance_first .dueTo{
    font-family: 'Muli', sans-serif;
    color: #6e6c6c;
    font-weight: 500;
    text-transform: uppercase;
}

.blance_first .dueAfter {
    font-family: 'Muli', sans-serif;
    font-weight: 600px;
    color: #373737;
    padding-bottom: 10px;
}

/* Invoice_show styling start here========>>>>>>> */
.Invoice_show {
    width: 100%;
    height: auto;
    margin: auto;
}

.Invoice_show .payment {
    /* width: 70%; */
    height: auto;
    margin: auto;
    /* padding: 20px; */
}

.payment .pay_head h2 {
    text-align: center;
    color: #393939;
    font-family: 'Muli', sans-serif;
    font-weight: 500;
    font-size: 16px;
}
.statement_select{
    background-color: #696868;
    color: #e6e4e4;
    padding: 5px 10px;
    border: none;
    outline: none;
    border-radius: 5px;

}
.payment .final_balance {
    /* width: 98%; */
    height: auto;
    margin-top: 20px;
}

.final_balance .table {
    width: 100%;
    border-collapse: collapse;
}

.table tr {
    text-align: left;
}

.table tr th {
    padding: 5px;
    color: #393939;
    font-family: 'Muli', sans-serif;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid #c1c1c17a;
}

.table tr td {
    padding: 5px;
    color: #37373796;
    font-family: 'Muli', sans-serif;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid #c1c1c17a;
}

@media print{
    .statement_page_inner{
        width: 98%;
    }
    .customer_profile_up{
        display: none;
    }
    .acc_details .right_side{
        width: 70%;
    }
}

.greenTickMain{
    display: grid;
    grid-template-columns: 50% auto;
    gap: 20px;
    justify-content: space-between;
}
.greenTickContainer h2{
    text-align: center;
    padding-bottom: 15px;
    color: #393939c9;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
}
.greenTickDetailsContainer h5{
    font-size: 16px;
    color: #393939c9;
    font-weight: 400;
    letter-spacing: 1px;
}
.greenTickDetails{
    margin-top: 10px;
    display: grid;
    grid-template-columns: auto 15px auto;
    gap: 10px;
    align-items: center;
}
.greenTickDetails .greenTickTitle{
   font-size: 14px;
   color: #393939C9;
   font-weight: 600;
}
.greenTickDetails .greenTickContent{
    font-size: 14px;
    color: #393939C9;
 }
 .greenTickRightContainer{
    border: 1px solid #b9b9b9c9;
    padding: 15px;
    border-radius: 10px;
 }
 .greenTickButton{
    padding: 10px 10px 0 10px;
    text-align: center;
 }

 .greenTickButton button{
    padding: 10px 20px;
    text-align: center;
    border-radius: 5px;
    border: none;
    background-color: #1877F2;
    color: #FFFFFF;
    font-size: 16px;
    cursor: pointer;
 }
 .greenTickSelect{
    padding: 10px 20px;
    border: none;
    outline: 1px solid #d6d5d5c9;
    background-color: #FFFFFF;
    border-radius: 5px;
    font-size: 14px;
    color: #393939C9;
    cursor: pointer;
 }