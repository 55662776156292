.whatsapp-lis {
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 502px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;

}

.whatsapp-list-item-containe {
  border: 1px solid #1877F2;
  border-radius: 5px;
  width: 100%;
  font-size: 14px;
  color: #393939;
  height: auto;
  position: relative;
  padding: 10px;
}

.customers_headein {
  display: flex;
  align-items: center;
  justify-content: space-between
}