/* --------resusable input field--------- */
.input-main {
  display: flex;
  flex-direction: column;
  min-width: 150px;
  /* width: 150px; */
  gap: 0;
  position: relative;
  margin-top: 14px;
}

.input-main span {
  font-size: 14px;
  position: absolute;
  top: -8px;
  left: 9px;
  color: gray;
  background-color: #fff;
  padding: 0 3px;
}
.input-main input {
  min-height: 28px;
  border-radius: 5px;
  border: none;
  outline: 1px solid gray;
  padding: 3px 10px;
  margin-top: 3px;
}
.input-main input:focus {
  min-height: 28px;
  border-radius: 5px;
  border: none;
  outline: 1px solid #1877F2;
  padding: 3px 10px;
}
.input-main .endIcon {
  position: absolute;
  top: 7px;
  right: 6px;
}

/* -------- button component-------- */
.button-wrap {
  padding: 5px;
  font-size: 14px;
  border-radius: 5px;
  background-color: #1976d2;
  outline: 1px solid #1976d2;
  border: 2px solid #ffffff;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  min-width: 60px;
}
.red-back {
  background-color: red !important;
  color: #FFFFFF !important;
  outline: 1px solid red;
}
.green {
  background: green;
}
.purple {
  background: purple;
}
.yellow {
  background: yellow;
}
.black {
  background: black;
  color: white;
}
.button-wrap:hover {
  background: #555555;
}
.button-wrap:active {
  background: #555555;
}

.simpleBtn {
  outline: none;
  background-color: #fff;
  border: 1px solid gray;
  border-radius: 5px;
  min-width: 35px;
  color: black;
  min-height: 20px;
}
.simpleBtn:hover {
  background-color: #fff;
}

/* ---------- Invoice Card --------- */
.invoice-card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 0;
  border: 1px solid #e2e8f0;
  width: 250px;
  height: 60px;
  padding: 0 15px;
  border-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  font-weight: 500;
  font-size: 16px;
}
.invoice-card p {
  margin: 0;
}
.yellowColor {
  color: #f1bb29;
}
.purpleColor {
  color: #6476a9;
}
.blueColor {
  color: #1dad96;
}
.invoice-card span {
  color: #2563eb;
}


/* ---------dropdown css----------- */
.select-box select{
  outline: none;
  color: rgb(51, 48, 48);
  height: 27px;
  min-width: 200px;
  border-radius: 5px;
  background-color: #fff;
  font-weight: 500;
  padding: 2px;
}
 /* ------------Subscription Card------------  */

.subscard-main{
  display: flex;
  gap: 5px;
  font-size: 14px;
  width: 150px;
  height: 60px;
  padding: 20px;
  align-items: center;
  gap: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
} 

.green{
  color: #88CB75;
  background-color: #fff;
}
.blue{
  color: #1BBDFA;
  background-color: #fff;
}
.lightgreen{
  color: #98C975;
  background-color: #fff;
}
.lightorange{
  color: #FF7831;
  background-color: #fff;
}
.red{
  color: #FC3048;
  background-color: #fff;
}
.darkorange{
  color: #FF8610;
  background-color: #fff;
}
.gray{
  color: #7B848D;
}
.lightgray{
  color: rgb(137,132,172)
}