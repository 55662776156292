.headingComponents{
  font-size: 24px;
  color: #494949;
  display: flex;
  /* align-items: center; */
  gap: 10px;
}
.subHeading{
  padding: 10px 0 0 10px;
  font-size: 18px;
  color: #1877f2;
}
.editProfile{
  display: grid;
  grid-template-columns: 150px 10px auto;
  gap: 10px;
  padding: 10px;
}
.subTitle{
  color: #494949;
  font-size: 14px;
  font-weight: 500;
}
.subText{
  font-size: 14px;
  color: #494949;
  display: inline-flex;

}
.editableField input{
  padding: 5px;
  border: none;
  outline: 1px solid #c5c4c4;
  border-radius: 5px;
}
.editableField input:focus{
  padding: 5px;
  border: none;
  outline: 1px solid #1877f2;
  border-radius: 5px;
}
.edotButtonSection{
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 10px;
}
.edotButtonSection button{
  border: none;
  padding: 5px 10px;
  border-radius: 40px;
  cursor: pointer;
}
.edotButtonSection button:nth-child(1){
  color: #FFFFFF;
  background-color: #1877f2;
}
.edotButtonSection button:nth-child(2){
  color: #FFFFFF;
  background-color: #dc2f3d;
}