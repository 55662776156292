.customers_page {
    padding: 10px;
    max-height: 93vh;
    overflow: auto;
}

.customers_headeing {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 10px 0;
}

.create_btn {
    background-color: #1976D2;
    color: #FFFFFF;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 2px solid #FFFFFF;
    outline: 1px solid #1976D2;
    cursor: pointer;
}

.filter_btn {
    background-color: #c5c3c3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    color: #006ac0;
    cursor: pointer;
}

.heading_right {
    position: relative;
}

.filter_option_container {
    position: absolute;
    background-color: #C5C3C3;
    border-radius: 10px;
    right: 0;
    top: 39px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.filter_option {
    display: flex;
    gap: 10px;
    color: #525151;
    font-size: 12px;
    padding: 5px 10px;
    transition: all 0.5s;

}

.filter_option:hover {
    display: flex;
    gap: 10px;
    color: #f7f4f4;
    transition: all 0.5s;
    font-size: 12px;
    background-color: #393939;
    cursor: pointer;
}

.customer_table_container {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px;
    border-radius: 10px;
    padding: 10px;
}

.customer_summery {
    display: flex;
    gap: 5px;
    color: #393939;
}

.summery_container {
    padding: 10px 0px;
    display: flex;
    /* justify-content: space-between; */
    gap: 24px;
    border-bottom: 1px solid #c5c3c3;
    padding-bottom: 24px;
    flex-wrap: wrap;
}

.summery_container .summery_card:nth-child(1) {
    border-left: none;
}

.summery_card {
    width: fit-content;
    padding-left: 10px;
    border-left: 1px solid #c5c3c3;
    cursor: pointer;
}

.summery_card_inner {
    display: flex;
    gap: 10px;
    align-items: center;
    color: #393939;
}

.summery_card_inner span:nth-child(1) {
    font-size: 14px;
    font-weight: 600;
}

.summery_card_inner span:nth-child(2) {
    font-size: 12px;
    font-weight: 500;
}

.customer_table {
    width: 100%;
    max-height: 80vh;
    overflow: auto;
}

.customer_table table {
    width: 100%;
    border-collapse: collapse;
    position: relative;
}

.customer_table table thead tr {
    background-color: #f1eeee;
    position: sticky;
    top: 0;
}

.customer_table table thead tr th {
    padding: 10px 5px;
    color: #393939;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

.customer_table table tbody tr td {
    font-size: 12px;
    color: #393939;
    padding: 10px 5px;
    border-bottom: 1px solid #f3f2f2;
}

.customer_table table tbody tr td input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.customer_table table tbody tr td label {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 20px;
    background: grey;
    display: block;
    border-radius: 40px;
    position: relative;
}

.customer_table table tbody tr td label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 4px;
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

.customer_table table tbody tr td input:checked+label {
    background: #1976D2;
}

.customer_table table tbody tr td input:checked+label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

.customer_table table tbody tr td label:active:after {
    width: 13px;
}

.input_search {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn_wrapper {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100%;
}

.emp_label {
    font-size: 14px;
    color: #575555;
    font-weight: 600;
}

.emp_check {
    width: 10px;
}

.action_btns {
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 16px !important;
}

.action_btns .action_icon_view {
    color: #1976D2;
    cursor: pointer;
}

.reminder-eye-icon {
    font-size: 16px !important;
    color: #1976D2;
    cursor: pointer;
}

.action_btns .action_icon_edit {
    color: #5fc756;
    cursor: pointer;
}

.action_btns .action_icon_delete {
    color: #f13838;
    cursor: pointer;
}

/* centering */
.back_btn {
    background-color: #c5c3c3;
    padding: 3px 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    color: #575555;
    border-radius: 40px;
    cursor: pointer;
    border: 3px solid #FFFFFF;
}

.back_btn:hover {
    background-color: #c5c3c3;
    padding: 3px 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    color: #575555;
    border-radius: 40px;
    cursor: pointer;
    border: 3px solid #FFFFFF;
    outline: 1px solid #C5C3C3;
}

.back_btn span {
    display: inline-flex;
    align-items: center;
}

.customer_form_up {
    display: flex;
    gap: 24px;
    font-size: 14px;
    align-items: center;
    padding-bottom: 10px;
}

.detail_btn {
    border-bottom: 2px solid #FFFFFF;
    cursor: pointer;
    padding: 10px;
}

.detail_btn:hover {
    border-bottom: 2px solid #575555;
}

@media print {
    .customers_headeing {
        display: none;
    }
}

/* Employee confirm popup */
.search-input {
    border: none;
    outline: solid 1px #9a9a9a;
    border-radius: 5px;
    padding: 5px;
}

.search-input:focus {
    border: none;
    outline: solid 1px #1877F2;
    border-radius: 5px;
    padding: 5px;
}

.confirm-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #00000056;
}

.confirm-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.confirm-main-container {
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 10px;
}

.confirm-icon {
    text-align: center;
    padding-bottom: 5px;
    color: #f62229;
}

.confirm-buttons {
    padding: 10px;
    display: flex;
    justify-content: space-around;
    gap: 10px;
    align-items: center;
}
.confirm-cancel{
    background-color: #f13838;
    border: none;
    padding: 5px;
    border-radius: 5px;
    color: #FFFFFF;
    cursor: pointer;
}
.confirm-submit{
    background-color: #1877F2;
    border: none;
    padding: 5px;
    border-radius: 5px;
    color: #FFFFFF;
    cursor: pointer;
}
.confirm-text{
    font-size: 14px;
    color: #595959;
}

.company-form{
    display: block !important;
}
.formControl1{
    border: none !important
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    max-width: 650px !important;
}
.close-whatsapp {
    position: absolute;
    top: -10px;
    right: -10px;
    color: #f32222;
    background-color: #faa7a7;
    border-radius: 0 5px 0 5px;
    padding: 5px;
    cursor: pointer;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }