@media (max-width:320px){
  .agent_details form{
    display: block;
  }
  form label {
    color: #475569;
    font-size: 12px;
    font-weight: 600;
    /* position: absolute; */
    left: 10px;
  }

  .form-control {
    margin-top: 5px;
    /* border: 1px solid #CBD5E1; */
    border-radius: 5px;
  }
  select.form-control {
    margin: 1px 0px 15px;
    width: 100%;
    height: 40px;
    background: transparent;
  }
  button.btn{
    font-size: 12px;
  }
  .end_section button{
    font-size: 12px;
  }
}

@media (min-width:321px) and (max-width:480px){
	.agent_details form {
    display: block;
  }

  form label {
    color: #475569;
    font-size: 14px;
    font-weight: 600;
    /* position: absolute; */
    left: 20px;
  }

  .form-control {
    margin-top: 5px;
    /* border: 1px solid #CBD5E1; */
    border-radius: 5px;
  }
  select.form-control {
    margin: 1px 0 15px;
    width: 100%;
  }
}

@media (min-width:480px) and (max-width:680px){
	.agent_details form {
    display: block;
  }

  form label {
    color: #475569;
    font-size: 14px;
    font-weight: 600;
    /* position: absolute; */
    left: 20px;
  }

  .form-control {
    /* border: 1px solid #CBD5E1; */
    border-radius: 5px;
  }
}

@media (min-width:680px) and (max-width:767px){
	.agent_details form {
    display: flex;
    justify-content: space-around;
    gap: 50px;
  }

  form label {
    color: #475569;
    font-size: 14px;
    font-weight: 600;
    /* position: absolute; */
    left: 20px;
  }

  .form-control {
    /* border: 1px solid #CBD5E1; */
    border-radius: 5px;
  }
  select.form-control {
    margin: 1px 0 15px;
    width: 100%;
  }
}
@media (min-width:767px) and (max-width:1024px){
	.agent_details form {
    display: flex;
    justify-content: space-around;
    gap: 50px;
  }

  form label {
    color: #475569;
    font-size: 14px;
    font-weight: 600;
    /* position: absolute; */
    left: 20px;
  }

  .form-control {
    /* border: 1px solid #CBD5E1; */
    border-radius: 5px;
  }
  select.form-control {
    margin: 1px 0 15px;
    width: 100%;
  }
}