.customer_profile_up{
    border-bottom: 1px solid #dddcdc;
    margin-bottom: 10px;
}
.profile_options{
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
}
.profile_option{
    display: flex;
    gap: 5px;
    color: #393939;
    cursor: pointer;
    font-size: 14px;
    border: 1px solid #f7f5f5;
    background-color: #f7f5f5;
    padding: 5px 5px;
    border-radius: 5px;
    transition: all 0.5s;
    align-items: center;
}
.profile_option:hover{
    border: 1px solid #3b3b3b;
    background-color: #3b3b3b;
    color: #f3f1f1;
}
.profile_option_active{
    border: 1px solid #3b3b3b !important;
    background-color: #3b3b3b !important;
    color: #f3f1f1 !important;
}
.customer_details_sec{
    display:  grid;
    grid-template-columns: 40% auto;
    gap: 10px;
}
.cust_title{
    color: #3b3b3b;
    font-size: 16px;
    padding: 5px 0;
    font-weight: 500;
}
.profile{
    display: flex;
    flex-direction: column;
    transition: all 0.3s;
}
.profile_img{
    display: flex;
    justify-content: center;
    position: relative;
    height: auto;
    padding: 10px 0;
    margin-bottom: 10px;
    background-color: #1877F2;
    transition: all 0.3s;
}
.topup-box {
    width: 150px;
    background-color: #FFFFFF29;
    position: absolute;
    top: 0;
    left: 0;
    height: 60px;
    /* padding: 5px 20px; */
    border-radius: 0 0 100px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.company-tracker{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #595959;
    gap: 10px;
}
.company-tracker span:nth-child(1){
    border: 1px soli #1877F2;
    background-color: #FFFFFF;
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 18px;
}
.company-tracker span:nth-child(2){
    color: #FFFFFF;
    font-size: 16px;
}
.company-tracker span:nth-child(3){
    color: #FFFFFF;
    font-size: 16px;
    background-color: #FFFFFF49;
    border-radius: 20px;
    padding: 5px 10px;
}
.canvas_agents{
    width: 200px !important;
    height: 200px !important;
}
.donught-container{
    background-color: #1877F229;
    border-radius: 10px;
    padding: 5px;
    /* width: 100%; */
    position: relative;
}
.donught-child{
    background-color: #1877F2;
    border-radius: 5px;
    padding: 5px;
    color: #FFFFFF;
    font-size: 14px;
    margin-top: 10px;
}
.total-member-allowed {
    position: absolute;
    top: 41%;
    width: 80px;
    text-align: center;
    left: 31%;
    font-size: 12px;
    color: #1877F2;
    font-weight: 600;
    z-index: 1;
}
/* .profile_img span{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #bef6fa;
    color: #05ebfc;
    font-size: 24px;
    font-weight: 600;
    position: absolute;
    bottom: -24px;
    border: 4px solid #FFFFFF;
    transition: all 0.3s;
} */
.personal_details{
    display: grid;
    grid-template-columns: 35% 20px auto;
    color: #3b3b3b;
    font-size: 14px;
    gap: 8px;
}
.naming_title {
    font-weight: 500;
}
.address_table table{
    width: 100%;
    border-collapse: collapse;
    transition: all 0.3s;
}
.address_table table thead tr{
    font-size: 14px;
    text-align: left;
    color: #393939;
    background-color: #f3f1f1;
    padding: 10px 3px;
    transition: all 0.3s;
}
.address_table table thead tr th{
    font-size: 14px;
    text-align: left;
    color: #393939;
    background-color: #f3f1f1;
    padding: 10px 3px;
    font-weight: 500;
    transition: all 0.3s;
}
.address_table table tbody tr td{
    padding: 15px 3px;
    color: #3b3b3b;
    font-size: 14px;
    transition: all 0.3s;
}
.address_table table tbody tr td:nth-child(1){
    padding: 10px 3px;
    color: #393939;
    font-size: 14px;
    background-color: #F3F1F1;
    font-weight: 500;
}
.proposal_customer_btnHead{
    display: flex !important;
    justify-content: space-between !important;
}


.profile_accordion{
    padding: 10px;
}
.accordion_button{
    width: 100%;
    border: none;
    cursor: pointer;
}
.accordion_button:hover{
    background-color: #a5a2a2;
}
.inner_accordion{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.accordion_description{
    padding: 10px;
    background-color: #FFFFFF;
    overflow: hidden;
    font-size: 14px;
    color: #595959;
    display: none;
}
.accordion_description_expand{
    display: block !important;
}
.catDropDown{
    padding: 5px 10px;
    cursor: pointer;
}
.catDropDown:hover{
    background-color: #a5a2a2;
}
.rightPaymentCat{
    rotate: -90deg;
    transition: all 0.5s linear;
  }
  .downPaymentCat{
    rotate: 0deg;
    transition: all 0.5s linear;
  }